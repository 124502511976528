import React from "react";
import { AuthRoles } from "components/auth";

export const ReportExitVehiclesConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/report_exit_vehicles`,
      component: React.lazy(() => import("./ReportExitVehicles")),
    },
  ],
};
