import React from "react"
import axios from "axios"
import { toast } from "react-toastify"
import { toast as reactHotToast } from 'react-hot-toast'
import { urlPdfs } from 'constants/index'

import * as Actions from "components/auth/store/actions"
import InfoNoStockProduct from "components/pages/helpers/info-no-stock-product/InfoNoStockProduct"

export const GET_MOTIVES = "[ENTRIES AND OUTPUTS] GET MOTIVES"
export const SAVE_ENTRY = "[ENTRIES AND OUTPUTS] SAVE ENTRY"
export const GET_ENTRIES = "[ENTRIES AND OUTPUTS] GET ENTRIES"
export const GET_ENTRY = "[ENTRIES AND OUTPUTS] GET ENTRY"
export const DELETE_ENTRY = "[ENTRIES AND OUTPUTS] DELETE ENTRY"
export const SAVE_OUTPUT = "[ENTRIES AND OUTPUTS] SAVE OUTPUT"
export const GET_OUTPUTS = "[ENTRIES AND OUTPUTS] GET OUTPUTS"
export const GET_OUTPUT = "[ENTRIES AND OUTPUTS] GET OUTPUT"
export const DELETE_OUTPUT = "[ENTRIES AND OUTPUTS] DELETE OUTPUT"

export function getMotives(entorn = 0) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/motivo/${entorn}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_MOTIVES,
        payload: response.data.detalles,
      })
    })
}

export function saveEntry(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rentrada`, from)
  return (dispatch) => {
    dispatch({ type: SAVE_ENTRY, payload: { loading: true } })
    toast.info("Guardando entrada...", { toastId: "saveEntrie", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        window.open(`${urlPdfs.entradaA4}/${response.data.detalles.identrada}`, "_blank")
        dispatch({ type: SAVE_ENTRY, payload: { saved: true, loading: false } })
        toast.update('saveEntrie', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('saveEntrie', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: SAVE_ENTRY, payload: { saved: false, loading: false } })
      }, 50)
    }).catch((error) => {
      console.log(SAVE_ENTRY, error)
      dispatch({ type: SAVE_ENTRY, payload: { saved: false, loading: false } })
      toast.update('saveEntrie', { render: "Error al guardar la entrada", type: "error", autoClose: 5000 })
    })
  }
}

export function updateEntry(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/uentrada`, from)
  return (dispatch) => {
    dispatch({ type: SAVE_ENTRY, payload: { loading: true } })
    toast.info("Actualizando entrada...", { toastId: "updateEntry", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        window.open(`${urlPdfs.entradaA4}/${response.data.detalles.identrada}`, "_blank")
        dispatch({ type: SAVE_ENTRY, payload: { saved: true, loading: false } })
        toast.update('updateEntry', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('updateEntry', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: SAVE_ENTRY, payload: { saved: false, loading: false } })
      }, 50)
    }).catch((error) => {
      console.log(SAVE_ENTRY, error)
      dispatch({ type: SAVE_ENTRY, payload: { saved: false, loading: false } })
      toast.update('updateEntry', { render: "Error al actualizad la entrada", type: "error", autoClose: 5000 })
    })
  }
}

export function getEntries(userId, page = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/entrada/${userId}?page=${page}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ENTRIES, payload: response.data })
    })
}

export function getEntry(entryId, useLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gentrada/${entryId}`)
  return (dispatch) => {
    if (useLoading) {
      toast.info("Obteniendo entrada...", { toastId: "getEntry", autoClose: false })
      dispatch({ type: GET_ENTRY, payload: { loading: true } })
    }
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_ENTRY, payload: { loading: false, data: response.data.detalles } })
        if (useLoading) {
          toast.dismiss("getEntry")
        }
      } else {
        toast.update('getEntry', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        return dispatch({ type: GET_ENTRY, payload: { loading: false, data: null } })
      }, 50)
    })
  }
}

export function deleteEntry(entryId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/aentrada/${entryId}`)
  return (dispatch) => {
    dispatch({ type: DELETE_ENTRY, payload: { loading: true } })
    toast.info("Anulando entrada...", { toastId: "deleteEntry", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: DELETE_ENTRY, payload: { deleted: true, loading: false } })
        toast.update('deleteEntry', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('deleteEntry', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: DELETE_ENTRY, payload: { deleted: false, loading: false } })
      }, 50)
    }).catch((error) => {
      console.log(DELETE_ENTRY, error)
      dispatch({ type: DELETE_ENTRY, payload: { deleted: false, loading: false } })
    })
  }
}

export function saveOutput(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rsalida`, from)
  return (dispatch) => {
    dispatch({ type: SAVE_OUTPUT, payload: { loading: true } })
    toast.info("Guardando salida...", { toastId: "saveOutput", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        window.open(`${urlPdfs.salidaA4}/${response.data.detalles.idsalida}`, "_blank")
        dispatch({ type: SAVE_OUTPUT, payload: { saved: true, loading: false } })
        toast.update('saveOutput', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        if (response.data.info === "no_stock") { // si no hay stock de algun producto
          toast.dismiss('saveOutput')
          let msg = response.data.mensaje.split('|')
          reactHotToast.custom(<InfoNoStockProduct msg={msg} />, { duration: 10000 })
        } else {
          toast.update('saveOutput', { render: response.data.mensaje, type: "error", autoClose: 5000 })
        }
      }
      setTimeout(() => {
        dispatch({ type: SAVE_OUTPUT, payload: { saved: false, loading: false } })
      }, 50)
    }).catch((error) => {
      console.log(SAVE_OUTPUT, error)
      dispatch({ type: SAVE_OUTPUT, payload: { saved: false, loading: false } })
      toast.update('saveOutput', { render: "Error al guardar la salida", type: "error", autoClose: 5000 })
    })
  }
}

export function updateOutput(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/usalida`, from)
  return (dispatch) => {
    dispatch({ type: SAVE_OUTPUT, payload: { loading: true } })
    toast.info("Actualizando salida...", { toastId: "updateOutput", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        window.open(`${urlPdfs.salidaA4}/${response.data.detalles.idsalida}`, "_blank")
        dispatch({ type: SAVE_OUTPUT, payload: { saved: true, loading: false } })
        toast.update('updateOutput', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        if (response.data.info === "no_stock") { // si no hay stock de algun producto
          toast.dismiss('updateOutput')
          let msg = response.data.mensaje.split('|')
          reactHotToast.custom(<InfoNoStockProduct msg={msg} />, { duration: 10000 })
        } else {
          toast.update('updateOutput', { render: response.data.mensaje, type: "error", autoClose: 5000 })
        }
      }
      setTimeout(() => {
        dispatch({ type: SAVE_OUTPUT, payload: { saved: false, loading: false } })
      }, 50)
    }).catch((error) => {
      console.log(SAVE_OUTPUT, error)
      dispatch({ type: SAVE_OUTPUT, payload: { saved: false, loading: false } })
      toast.update('updateOutput', { render: "Error al actualizar salida", type: "error", autoClose: 5000 })
    })
  }
}

export function getOutputs(userId, page = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/salida/${userId}?page=${page}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_OUTPUTS, payload: response.data })
    })
}

export function getOutput(outputId, useLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gsalida/${outputId}`)
  return (dispatch) => {
    if (useLoading) {
      toast.info("Obteniendo salida...", { toastId: "getOutput", autoClose: false })
      dispatch({ type: GET_OUTPUT, payload: { loading: true } })
    }
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_OUTPUT, payload: { loading: false, data: response.data.detalles } })
        if (useLoading) {
          toast.dismiss("getOutput")
        }
      } else {
        toast.update('getOutput', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        return dispatch({ type: GET_OUTPUT, payload: { loading: false, data: null } })
      }, 50)
    })
  }
}

export function deleteOutput(outputId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/asalida/${outputId}`)
  return (dispatch) => {
    dispatch({ type: DELETE_OUTPUT, payload: { loading: true } })
    toast.info("Anulando salida...", { toastId: "deleteOutput", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: DELETE_OUTPUT, payload: { deleted: true, loading: false } })
        toast.update('deleteOutput', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('deleteOutput', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: DELETE_OUTPUT, payload: { deleted: false, loading: false } })
      }, 50)
    }).catch((error) => {
      console.log(DELETE_OUTPUT, error)
      dispatch({ type: DELETE_OUTPUT, payload: { deleted: false, loading: false } })
    })
  }
}