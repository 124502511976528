import * as Actions from "../../actions/app"

const initialState = {

  stores: null,
  crud_store: false,
  loading_crud_store: false,



}

const storesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_STORES: {
      return {
        ...state,
        stores: action.payload,
      }
    }
    case Actions.CRUD_STORE: {
      return {
        ...state,
        loading_crud_store: action.payload.loading,
        crud_store: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}

export default storesReducer
