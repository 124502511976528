import React from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';

const InfoNoStockProduct = (props) => {
  const { msg } = props;

  return (
    <div className="py-2 px-3 bg-danger text-white"
      style={{ borderRadius: 8, fontSize: 16, width: 350 }}
    >
      <div>
        {msg[0]}
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <span>Existen:</span>
        <span>{msg[1]} unidades</span>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <span>Seleccionaste:</span>
        <span>{msg[2]} unidades</span>
      </div>
      <div className="mt-3 mb-2 text-end">
        <Button variant="light" className="text-dark"
          style={{ borderRadius: 4 }}
          size='sm' onClick={() => toast.dismiss()}
        >
          Cerrar
        </Button>
      </div>
    </div>
  )
};
export default InfoNoStockProduct;
