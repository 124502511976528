import * as Actions from "../../actions/app"

const initialState = {

  loading_vehicles_requirements: false,
  vehicles_requirements: null,
  total_pages_vehicles_requirements: 1,
  total_registers_vehicles_requirements: 0,

  loading_crud_vehicle_requirement: false,
  crud_vehicle_requirement: false,

  loading_requirements_pendings_vehicle: false,
  requirements_pendings_vehicle: null,

}

const vehicleRequirementsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LOADING_VEHICLES_REQUIREMENTS: {
      return {
        ...state,
        loading_vehicles_requirements: action.payload,
      }
    }
    case Actions.GET_VEHICLES_REQUIREMENTS: {
      return {
        ...state,
        vehicles_requirements: action.payload.detalles,
        total_pages_vehicles_requirements: action.payload.totalPaginas,
        total_registers_vehicles_requirements: action.payload.totalRegistros,
      }
    }
    case Actions.CRUD_VEHICLE_REQUIREMENT: {
      return {
        ...state,
        loading_crud_vehicle_requirement: action.payload.loading,
        crud_vehicle_requirement: action.payload.crud,
      }
    }
    case Actions.GET_REQUIREMENTS_PENDINGS_BY_VEHICLE: {
      return {
        ...state,
        loading_requirements_pendings_vehicle: action.payload.loading,
        requirements_pendings_vehicle: action.payload.data,
      }
    }
    default: {
      return state
    }
  }
}
export default vehicleRequirementsReducer
