import React from "react"
import axios from "axios"
import * as Actionss from "components/auth/store/actions"

import { toast } from "react-toastify"
import { toast as reactHotToast } from 'react-hot-toast'
import InfoNoStockProduct from "components/pages/helpers/info-no-stock-product/InfoNoStockProduct"

import { LOADING_CPE_ACTION } from "./manage_cpe.actions"

export const GET_ORDERS = "[ORDERS] GET ORDERS"
export const GET_ORDER = "[ORDERS] GET ORDER"
export const CRUD_ORDER = "[ORDERS] CRUD ORDER"

export const CRUD_PRODUCT_ORDER = "[ORDERS] CRUD PRODUCT ORDER"
export const GET_PRODUCTS_ORDER = "[ORDERS] GET PRODUCTS ORDER"

export function getOrders(userId, page = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/pedidos/${userId}?page=${page}`)
  return dispatch =>
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {

          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ORDERS, payload: response.data })
    })
}

export function getOrder(orderId, showLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/cpedido/${orderId}`)
  return (dispatch) => {
    dispatch({ type: GET_ORDER, payload: { loading: true } })
    if (showLoading) toast.info('Obteniendo pedido...', { toastId: 'getOrder', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (showLoading) toast.dismiss('getOrder')
      dispatch({ type: GET_ORDER, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_ORDER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_ORDER, payload: { loading: false, data: null } })
      toast.error("Error inesperado, no se pudo obtener el pedido", { toastId: 'getOrder' })
      console.log(error)
    })
  }
}

export function saveHeaderOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcpedido`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_ORDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_ORDER, payload: { loading: false, crud: true, data: response.data.detalles } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_ORDER, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_ORDER, payload: { loading: false, crud: false, data: null } })
      toast.error("Ocurrió un error inesperado")
      console.log(error)
    })
  }
}

export function saveProductOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rdpedido`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PRODUCT_ORDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_PRODUCT_ORDER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        if (response.data.info === "no_stock") { // si no hay stock de algun producto
          let msg = response.data.mensaje.split('|')
          reactHotToast.custom(<InfoNoStockProduct msg={msg} />, { duration: 10000 })
        } else toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_PRODUCT_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_PRODUCT_ORDER, payload: { loading: false, crud: false } })
      toast.error("Ocurrió un error inesperado")
      console.log(error)
    })
  }
}

export function deleteProductOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eproductopedido`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PRODUCT_ORDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_PRODUCT_ORDER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_PRODUCT_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_PRODUCT_ORDER, payload: { loading: false, crud: false } })
      toast.error("Ocurrió un error inesperado")
      console.log(error)
    })
  }
}

export function getProductsOrder(orderId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/productospedido/${orderId}`)
  return dispatch => {
    dispatch({ type: GET_PRODUCTS_ORDER, payload: { loading: true } })
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PRODUCTS_ORDER, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_PRODUCTS_ORDER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_PRODUCTS_ORDER, payload: { loading: false, data: null } })
      toast.error("Error inesperado, no se pudo obtener los detalles del pedido")
      console.log(error)
    })
  }
}

export function deleteOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/epedido`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_ORDER, payload: { loading: true, crud: false } })
    toast.info('Eliminando pedido...', { toastId: 'deletingOrder', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_ORDER, payload: { loading: false, crud: true } })
        toast.update("deletingOrder", { render: response.data.mensaje, type: 'success', autoClose: 3000 })
      } else {
        toast.update("deletingOrder", { render: response.data.mensaje, type: 'error', autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_ORDER, payload: { loading: false, crud: false } })
      toast.update("savingOrder", { render: "Error al registrar el pedido", type: 'error', autoClose: 5000 })
      console.log(error)
    })
  }
}

export function saveOrderByProforma(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rpedidodeproforma`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_ORDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_ORDER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        if (response.data.info === "no_stock") { // si no hay stock de algun producto
          toast.dismiss('saveSale')
          let msg = response.data.mensaje.split('|')
          reactHotToast.custom(<InfoNoStockProduct msg={msg} />, { duration: 10000 })
        } else {
          toast.error(response.data.mensaje)
        }
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_ORDER, payload: { loading: false, crud: false } })
      toast.error("Ocurrió un error inesperado")
      console.log(error)
    })
  }
}

export function sendOrderByMail(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/enviapedidocorreo`, form)
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Enviando comprobante por correo...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'success', autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'error', autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      console.log(error)
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}
