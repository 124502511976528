import axios from "axios"
import * as Actions from "components/auth/store/actions"
import { toast } from "react-toastify"

export const SAVE_SERIE_PRODUCT = "[SERIES PRODUCT] SAVE SERIE PRODUCT"
export const UPDATE_SERIE_PRODUCT = "[SERIES PRODUCT] UPDATE SERIE PRODUCT"
export const DELETE_SERIE_PRODUCT = "[SERIES PRODUCT] DELETE SERIE PRODUCT"
export const GET_SERIES_PRODUCT = "[SERIES PRODUCT] GET SERIES PRODUCT"
export const GET_SERIES_PRODUCT_RELATED = "[SERIES PRODUCT] GET SERIES PRODUCT RELATED"
export const GET_SERIES_RELATED = "[SERIES PRODUCT] GET SERIES RELATED"

export function saveSerieProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rserieproducto`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: true, saved: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: true } })
        return setTimeout(() => {
          dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
      }
    }).catch((error) => {
      console.log(SAVE_SERIE_PRODUCT, error)
      return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
    })
  }
}

export function updateSerieProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/aserieproducto`, form)
  return (dispatch) => {
    dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: true, updated: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: true } })
        return setTimeout(() => {
          dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: false } })
      }
    }).catch((error) => {
      console.log(UPDATE_SERIE_PRODUCT, error)
      return dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: false } })
    })
  }
}

export function changeStatusSerieProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cserieproducto`, form)
  return (dispatch) => {
    dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: true, updated: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: true } })
        return setTimeout(() => {
          dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: false } })
      }
    }).catch((error) => {
      console.log(UPDATE_SERIE_PRODUCT, error)
      return dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: false } })
    })
  }
}

export function deleteSerieProduct(serieId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/eserieproducto/${serieId}`)
  return (dispatch) => {
    dispatch({ type: DELETE_SERIE_PRODUCT, payload: { loading: true, deleted: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: DELETE_SERIE_PRODUCT, payload: { loading: false, deleted: true } })
        return setTimeout(() => {
          dispatch({ type: DELETE_SERIE_PRODUCT, payload: { loading: false, deleted: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: DELETE_SERIE_PRODUCT, payload: { loading: false, deleted: false } })
      }
    }).catch((error) => {
      console.log(DELETE_SERIE_PRODUCT, error)
      return dispatch({ type: DELETE_SERIE_PRODUCT, payload: { loading: false, deleted: false } })
    })
  }
}

export function quitSerieProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/quitarrelacionserie`, form)
  return (dispatch) => {
    dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: true, updated: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: true } })
        return setTimeout(() => {
          dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: false } })
      }
    }).catch((error) => {
      console.log(UPDATE_SERIE_PRODUCT, error)
      return dispatch({ type: UPDATE_SERIE_PRODUCT, payload: { loading: false, updated: false } })
    })
  }
}

export function quitSerieProductOnEdition(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/quitarserieproducto`, form)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      console.log("Se quitó la serie del producto ^-^")
    }).catch((error) => {
      console.log(UPDATE_SERIE_PRODUCT, error)
      toast.error("Error interno al intentar quitar serie del producto u.u")
    })
  }
}

export function getSeriesProduct(productId, useLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/seriesproducto/${productId}`)
  return (dispatch) => {
    if (useLoading) {
      dispatch({ type: GET_SERIES_PRODUCT, payload: { loading: true, data: [] } })
    }
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_SERIES_PRODUCT, payload: { loading: false, data: response.data.detalles } })
    }).catch((error) => {
      console.log(GET_SERIES_PRODUCT, error)
    })
  }
}

export function saveSerieProductFromBuy(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rserieproductocompra`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: true, saved: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: true } })
        return setTimeout(() => {
          dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
      }
    }).catch((error) => {
      console.log(SAVE_SERIE_PRODUCT, error)
      return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
    })
  }
}

export function saveSerieProductFromSale(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rserieproductoventa`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: true, saved: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: true } })
        return setTimeout(() => {
          dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
      }
    }).catch((error) => {
      console.log(SAVE_SERIE_PRODUCT, error)
      return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
    })
  }
}

export function saveSerieProductFromOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rserieproductopedido`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: true, saved: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: true } })
        return setTimeout(() => {
          dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
      }
    }).catch((error) => {
      console.log(SAVE_SERIE_PRODUCT, error)
      return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
    })
  }
}

export function saveSerieProductFromEntry(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rserieproductoentrada`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: true, saved: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: true } })
        return setTimeout(() => {
          dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
      }
    }).catch((error) => {
      console.log(SAVE_SERIE_PRODUCT, error)
      return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
    })
  }
}

export function saveSerieProductFromOutput(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rserieproductosalida`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: true, saved: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: true } })
        return setTimeout(() => {
          dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
        }, 50)
      } else {
        toast.error(response.data.mensaje)
        return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
      }
    }).catch((error) => {
      console.log(SAVE_SERIE_PRODUCT, error)
      return dispatch({ type: SAVE_SERIE_PRODUCT, payload: { loading: false, saved: false } })
    })
  }
}

export function getSeriesProductRelated(productId, relatedName, relatedId, useLoading = true) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/seriesproductorelacionado/${productId}/${relatedName}/${relatedId}`
  )
  return (dispatch) => {
    if (useLoading) {
      dispatch({ type: GET_SERIES_PRODUCT_RELATED, payload: { loading: true } })
    }
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({
        type: GET_SERIES_PRODUCT_RELATED, payload: {
          loading: false,
          data: response.data.detalles,
          seriesActives: response.data.series_activas
        }
      })
      setTimeout(() => {
        dispatch({ type: GET_SERIES_PRODUCT_RELATED, payload: { loading: false, data: null, seriesActives: 0 } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_SERIES_PRODUCT_RELATED, payload: { loading: false, data: null, seriesActives: 0 } })
      console.log(GET_SERIES_PRODUCT_RELATED, error)
    })
  }
}

export function getSeriesRelated(relatedName, relatedId, useLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/seriesrelacionado/${relatedName}/${relatedId}`)
  return (dispatch) => {
    if (useLoading) {
      dispatch({ type: GET_SERIES_RELATED, payload: { loading: true } })
    }
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SERIES_RELATED, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_SERIES_RELATED, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_SERIES_RELATED, payload: { loading: false, data: null } })
      console.log(GET_SERIES_RELATED, error)
    })
  }
}