/**
 * Obtiene datos adicionales del producto UMS basados en medidas de unidad y productos de medida de unidad.
 *
 * @param {Array} unitmeasures - El array de medidas de unidad
 * @param {Array} unitmeasureproduct - El array de unidades de medida del producto
 * @return {Array} El array de unidades de medida del producto mas los datos adicionales del array de unidades de medida
 */
export const getUmsProductMoreDataOfUms = (unitmeasures, unitmeasureproduct) => {
  if (unitmeasures && unitmeasureproduct) {
    if (unitmeasureproduct.length > 0) {
      const umsp = unitmeasureproduct
        .filter((ump) =>
          unitmeasures.some(
            (um) => parseInt(ump.unidad_medida_id) === parseInt(um.unidad_medida_id)
          )
        )
        .map((ump) => {
          const um = unitmeasures.find(
            (um) => parseInt(ump.unidad_medida_id) === parseInt(um.unidad_medida_id)
          )
          return {
            ...ump,
            ...um,
          }
        })
      return umsp
    } else {
      return []
    }
  }
}