import * as Actions from "../../actions/app"
const initialState = {
  motives: null,

  loading_save_entry: false,
  saved_entry: false,

  loading_deleted_entry: false,
  deleted_entry: false,

  entries: null,
  total_pages_entries: 1,
  total_registers_entries: 0,

  loading_get_entry: false,
  entry: null,

  loading_save_output: false,
  saved_output: false,

  loading_deleted_output: false,
  deleted_output: false,

  outputs: null,
  total_pages_outputs: 1,
  total_registers_outputs: 0,

  loading_get_output: false,
  output: null,
}

const entriesAndOutputsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_MOTIVES: {
      return {
        ...state,
        motives: action.payload,
      }
    }
    case Actions.SAVE_ENTRY: {
      return {
        ...state,
        loading_save_entry: action.payload.loading,
        saved_entry: action.payload.saved,
      }
    }
    case Actions.GET_ENTRIES: {
      return {
        ...state,
        entries: action.payload.detalles,
        total_pages_entries: action.payload.totalPaginas,
        total_registers_entries: action.payload.totalRegistros,
      }
    }
    case Actions.GET_ENTRY: {
      return {
        ...state,
        loading_get_entry: action.payload.loading,
        entry: action.payload.data,
      }
    }
    case Actions.DELETE_ENTRY: {
      return {
        ...state,
        loading_deleted_entry: action.payload.loading,
        deleted_entry: action.payload.deleted,
      }
    }
    case Actions.SAVE_OUTPUT: {
      return {
        ...state,
        loading_save_output: action.payload.loading,
        saved_output: action.payload.saved,
      }
    }
    case Actions.GET_OUTPUTS: {
      return {
        ...state,
        outputs: action.payload.detalles,
        total_pages_outputs: action.payload.totalPaginas,
        total_registers_outputs: action.payload.totalRegistros,
      }
    }
    case Actions.GET_OUTPUT: {
      return {
        ...state,
        loading_get_output: action.payload.loading,
        output: action.payload.data,
      }
    }
    case Actions.DELETE_OUTPUT: {
      return {
        ...state,
        loading_deleted_output: action.payload.loading,
        deleted_output: action.payload.deleted,
      }
    }
    default: {
      return state
    }
  }
}
export default entriesAndOutputsReducer
