import { format, startOfYear } from 'date-fns'

/**
 * Devuelve una cadena en el formato "YYYY-MM-DD" que representa la fecha actual.
 *
 * @param {boolean} [includeTime=false] - Indica si se debe incluir la hora en el formato de fecha.
 * @return {string} La cadena que representa la fecha actual.
 */
export const getStringDate = (includeTime = false) => {
    const currentDate = new Date()

    if (includeTime) {
        return format(currentDate, 'yyyy-MM-dd HH:mm:ss')
    }

    return format(currentDate, 'yyyy-MM-dd')
}

/**
 * Returns a string representing the current month in the format 'YYYY-MM'.
 *
 * @return {string} The current month in the format 'YYYY-MM'.
 */
export const getMonthDate = () => {
    const currentDate = new Date()
    return format(currentDate, 'yyyy-MM')
}

/**
 * A function that returns the first date of the year in the format 'yyyy-MM-dd'.
 *
 * @return {string} The first date of the year in the format 'yyyy-MM-dd'.
 */
export const getFirstStringDate = () => {
    const firstDate = startOfYear(new Date())
    return format(firstDate, 'yyyy-MM-dd')
}