import * as Actions from "../../actions/app"

const initialState = {
  guide_types: null,
  departaments: null,
  provinces: null,
  districts: null,

  loading_electronic_guides: false,
  electronic_guides: null,
  total_pages_guides: 1,
  total_registers: 0,

  loading_save_electronic_guide: false,
  saved_electronic_guide: false,
}

const electronicGuide = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_GUIDE_TYPES: {
      return {
        ...state,
        guide_types: action.payload,
      }
    }
    case Actions.GET_DEPARTAMENTS: {
      return {
        ...state,
        departaments: action.payload,
      }
    }
    case Actions.GET_PROVINCES: {
      return {
        ...state,
        provinces: action.payload,
      }
    }
    case Actions.GET_DISTRICTS: {
      return {
        ...state,
        districts: action.payload,
      }
    }
    case Actions.LOADING_ELECTRONIC_GUIDES: {
      return {
        ...state,
        loading_electronic_guides: action.payload,
      }
    }
    case Actions.GET_ELECTRONIC_GUIDES: {
      return {
        ...state,
        electronic_guides: action.payload.detalles,
        total_pages_guides: action.payload.totalPaginas,
        total_registers: action.payload.totalRegistros,
      }
    }
    case Actions.SAVE_ELECTRONIC_GUIDE: {
      return {
        ...state,
        loading_save_electronic_guide: action.payload.loading,
        saved_electronic_guide: action.payload.saved,
      }
    }

    default: {
      return state
    }
  }
}

export default electronicGuide
