import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_VEHICLES_REQUIREMENTS = "[VEHICLE REQUIREMENTS] GET VEHICLE REQUIREMENTS"
export const LOADING_VEHICLES_REQUIREMENTS = "[VEHICLE REQUIREMENTS] LOADING VEHICLES REQUIREMENTS"
export const GET_REQUIREMENT = "[VEHICLE REQUIREMENTS] GET REQUIREMENT"
export const GET_REQUIREMENTS_PENDINGS_BY_VEHICLE = "[VEHICLE REQUIREMENTS] GET REQUIREMENTS PENDINGS BY VEHICLE"
export const CRUD_VEHICLE_REQUIREMENT = "[VEHICLE REQUIREMENTS] CRUD VEHICLE REQUIREMENT"

export function getVehiclesRequirements(page = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/requerimientos?page=${page}`)
  return dispatch => {
    dispatch({ type: LOADING_VEHICLES_REQUIREMENTS, payload: true })
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_VEHICLES_REQUIREMENTS, payload: response.data })
      dispatch({ type: LOADING_VEHICLES_REQUIREMENTS, payload: false })
      return
    }).catch(error => {
      dispatch({ type: LOADING_VEHICLES_REQUIREMENTS, payload: false })
    })
  }
}

export function getVehicleRequirement(requirementId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/grequerimiento/${requirementId}`)
  return (dispatch) => {
    dispatch({ type: GET_REQUIREMENT, payload: { loading: true } })
    toast.info("Obteniendo requerimiento...", { toastId: "getVehicleRequirement", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_REQUIREMENT, payload: { loading: false, data: response.data.detalles } })
        toast.dismiss("getVehicleRequirement")
      } else {
        dispatch({ type: GET_REQUIREMENT, payload: { loading: false, data: null } })
        toast.update("getVehicleRequirement", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: GET_REQUIREMENT, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_REQUIREMENT, payload: { loading: false, data: null } })
      toast.update("getVehicleRequirement", { autoClose: 4000, type: "error", render: "Error al obtener el requerimiento" })
      console.log(error)
    })
  }
}

export function saveVehicleRequirement(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rrequerimiento`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: true } })
    toast.info("Guardando requerimiento...", { toastId: "saveVehicleRequirement", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: false, crud: true } })
        toast.update("saveVehicleRequirement", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveVehicleRequirement", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: false, crud: false } })
      toast.update("saveVehicleRequirement", { autoClose: 4000, type: "error", render: "Error al guardar el requerimiento" })
      console.log(error)
    })
  }
}

export function updateVehicleRequirement(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/arequerimiento`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: true } })
    toast.info("Actualizando requerimiento...", { toastId: "updateVehicleRequirement", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: false, crud: true } })
        toast.update("updateVehicleRequirement", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateVehicleRequirement", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: false, crud: false } })
      toast.update("updateVehicleRequirement", { autoClose: 4000, type: "error", render: "Error al actualizar el requerimiento" })
      console.log(error)
    })
  }
}

export function deleteVehicleRequirement(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/erequerimiento`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: true } })
    toast.info("Eliminando requerimiento...", { toastId: "deleteVehicleRequirement", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: false, crud: true } })
        toast.update("deleteVehicleRequirement", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteVehicleRequirement", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_REQUIREMENT, payload: { loading: false, crud: false } })
      toast.update("deleteVehicleRequirement", { autoClose: 4000, type: "error", render: "Error al eliminar el requerimiento" })
      console.log(error)
    })
  }
}

export function getRequerimentsPendingsByVehicle(vehicleId, showLoading = false) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/greqpendientesvehiculo/${vehicleId}`)
  return (dispatch) => {
    if (showLoading) {
      toast.info("Obteniendo requerimientos pendientes del vehiculo...", { toastId: "getRequerimentsPendingsByVehicle", autoClose: false })
    }
    dispatch({ type: GET_REQUIREMENTS_PENDINGS_BY_VEHICLE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      toast.dismiss("getRequerimentsPendingsByVehicle")
      dispatch({ type: GET_REQUIREMENTS_PENDINGS_BY_VEHICLE, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_REQUIREMENTS_PENDINGS_BY_VEHICLE, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_REQUIREMENTS_PENDINGS_BY_VEHICLE, payload: { loading: false, data: null } })
      toast.error("Error al obtener requerimientos pendientes del vehiculo")
      console.log(error)
    })
  }
}