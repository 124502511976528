import axios from "axios"
import * as Actions from "components/auth/store/actions"
import { toast } from "react-toastify"

import { LOADING_CPE_ACTION } from "./manage_cpe.actions"
export const GET_LIST_PROFORMAS = "[PROFORMAS] GET LIST PROFORMAS"
export const CRUD_PROFORMAS = "[PROFORMAS] CRUD PROFORMAS"
export const GET_DETAIL_PROFORMAS = "[PROFORMAS] GET DETAIL PROFORMAS"

export function getListProformas({ page = 1, userId = 0, branchId = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/proforma?page=${page}&idusuario=${userId}&idsucursal=${branchId}`
  )
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_LIST_PROFORMAS, payload: response.data })
    })
}

export function getDetailProformas(form) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/cproforma/${form}`)
  return (dispatch) => {
    toast.info('Obteniendo proforma...', { toastId: 'loadingProforma', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        toast.dismiss('loadingProforma')
      } else {
        toast.update('loadingProforma', { render: response.data.mensaje, type: 'error', autoClose: 4000 })
      }
      dispatch({ type: GET_DETAIL_PROFORMAS, payload: response.data.detalles })
      setTimeout(() => {
        dispatch({ type: GET_DETAIL_PROFORMAS, payload: null })
      }, 50)
      return
    }).catch((error) => {
      console.log(GET_DETAIL_PROFORMAS, error)
      toast.dismiss('loadingProforma')
    })
  }
}

export function saveProforma(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rproformaform`, from)
  return (dispatch) => {
    toast.info('Guardando proforma...', { toastId: 'savingProforma', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PROFORMAS, payload: true })
        toast.update('savingProforma', { render: response.data.mensaje, type: 'success', autoClose: 3000 })
      } else {
        toast.update('savingProforma', { render: response.data.mensaje, type: 'error', autoClose: 5000 })
      }
      setTimeout(() => {
        return dispatch({ type: CRUD_PROFORMAS, payload: false })
      }, 50)
    })
  }
}

export function updateProforma(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/aproformaform`, from)
  return (dispatch) => {
    toast.info('Actualizando proforma...', { toastId: 'updatingProforma', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PROFORMAS, payload: true })
        toast.update('updatingProforma', { render: response.data.mensaje, type: 'success', autoClose: 3000 })
      } else {
        toast.update('updatingProforma', { render: response.data.mensaje, type: 'error', autoClose: 5000 })
      }
      setTimeout(() => {
        return dispatch({ type: CRUD_PROFORMAS, payload: false })
      }, 50)
    })
  }
}

export function deleteProforma(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eproforma`, from)
  return (dispatch) => {
    toast.info('Eliminando proforma...', { toastId: 'deletingProforma', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PROFORMAS, payload: true })
        toast.update('deletingProforma', { render: response.data.mensaje, type: 'success', autoClose: 3000 })
      } else {
        toast.update('deletingProforma', { render: response.data.mensaje, type: 'error', autoClose: 5000 })
      }
      setTimeout(() => {
        return dispatch({ type: CRUD_PROFORMAS, payload: false })
      }, 50)
    })
  }
}

export function sendProformaByMail(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/enviaproformacorreo`, form)
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Enviando comprobante por correo...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'success', autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'error', autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      console.log(error)
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}
