import axios from "axios"
import * as Actions from "components/auth/store/actions"
import { toast } from "react-toastify"

import { getAllSales } from "./sales.actions"
import { getElectronicGuides } from "./electronic_guide.actions"
import { listCreditNote } from "./credit_note.actions"

export const LOADING_CPE_ACTION = "[MANAGE CPE] LOADING_CPE_ACTION"
export const CONSUlTA_COMPROBANTE_SUNAT = "[MANAGE CPE] CONSULTA COMPROBANTE SUNAT"

// VENTAS:

export function generateSale({ idventa, page = 1, userId = 0, branchId = 0, refresh = true }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/generaventa`, { idventa })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Generando...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        if (refresh) dispatch(getAllSales({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function signSale({ idventa, page = 1, userId = 0, branchId = 0, refresh = true }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/firmaventa`, { idventa })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Firmando...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        if (refresh) dispatch(getAllSales({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function sendSale({ idventa, page = 1, userId = 0, branchId = 0, refresh = true }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/enviaventa`, { idventa })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Enviando a SUNAT...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        if (refresh) dispatch(getAllSales({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function reverceSaleOrCreditNote({ id, tipo_documento, page = 1, userId = 0, branchId = 0 }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/revercecpe`, { id, tipo_documento })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Revirtiendo...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (response.data.status == 200) {
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })

        if (tipo_documento == 1) dispatch(getAllSales({ page, userId, branchId }))
        if (tipo_documento == 2) dispatch(listCreditNote({ page, userId, branchId }))

      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })

    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}


// GUIAS ELECTRONICAS:

export function generateGuide({ idguia, page = 1, userId = 0, branchId = 0, refresh = true }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/generaguia`, { idguia })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Generando...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        if (refresh) dispatch(getElectronicGuides({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function signGuide({ idguia, page = 1, userId = 0, branchId = 0, refresh = true }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/firmaguia`, { idguia })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Firmando...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        if (refresh) dispatch(getElectronicGuides({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function sendGuide({ idguia, page = 1, userId = 0, branchId = 0, refresh = true }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/enviaguia`, { idguia })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Enviando a SUNAT...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        if (refresh) dispatch(getElectronicGuides({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function reverceGuide({ idguia, page = 1, userId = 0, branchId = 0 }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/reverceguia`, { idguia })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Enviando a SUNAT...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        dispatch(getElectronicGuides({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}


// NOTAS DE CREDITO:

export function generateCreditNote({ idnota, page = 1, userId = 0, branchId = 0, refresh = true }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/generanotacredito`, { idnota })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Generando...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        if (refresh) dispatch(listCreditNote({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function signCreditNote({ idnota, page = 1, userId = 0, branchId = 0, refresh = true }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/firmanotacredito`, { idnota })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Firmando...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        if (refresh) dispatch(listCreditNote({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function sendCreditNote({ idnota, page = 1, userId = 0, branchId = 0, refresh = true }) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/envianotacredito`, { idnota })
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Enviando a SUNAT...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        if (refresh) dispatch(listCreditNote({ page, userId, branchId }))
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: response.data.type, autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}


// CONSULTAS Y ENVIOS POR CORREO:

export function consultSale(saleId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/consultaventa/${saleId}`,)
  return (dispatch) => {
    toast.info("Consultando en SUNAT...", { toastId: "consultSale", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        toast.update("consultSale", { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update("consultSale", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
    }).catch((error) => {
      return toast.update("consultSale", { render: error.message, type: "error", autoClose: 5000 })
    })
  }
}

export function sendSaleByMail(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/enviaventacorreo`, form)
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Enviando comprobante por correo...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'success', autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'error', autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      console.log(error)
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function consultGuide(guideId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/consultaguia/${guideId}`)
  return (dispatch) => {
    toast.info("Consultando en SUNAT...", { toastId: "consultGuide", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        toast.update("consultGuide", { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update("consultGuide", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
    }).catch((error) => {
      return toast.update("consultGuide", { render: error.message, type: "error", autoClose: 5000 })
    })
  }
}

export function sendGuideByMail(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/enviaguiacorreo`, form)
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Enviando comprobante por correo...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'success', autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'error', autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      console.log(error)
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}

export function consultCreditNote(noteId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/consultanota/${noteId}`)
  return (dispatch) => {
    toast.info("Consultando en SUNAT...", { toastId: "consultCreditNote", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        toast.update("consultCreditNote", { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update("consultCreditNote", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
    }).catch((error) => {
      return toast.update("consultCreditNote", { render: error.message, type: "error", autoClose: 5000 })
    })
  }
}

export function sendCreditNoteByMail(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/envianotacreditocorreo`, form)
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true })
    toast.info("Enviando comprobante por correo...", { toastId: "mssgCpe", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status == 200) {
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'success', autoClose: 5000 })
      } else {
        console.log(response.data.detalles)
        toast.update("mssgCpe", { render: response.data.mensaje, type: 'error', autoClose: 5000 })
      }
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    }).catch((error) => {
      console.log(error)
      toast.update("mssgCpe", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: LOADING_CPE_ACTION, payload: false })
    })
  }
}