import * as Actions from "../../actions/app"

const initialState = {

  loading_crud_loan: false,
  crud_loan: false,

  loans_customers: null,

  loading_loans_by_customer: false,
  loans_by_customer: null,

  loading_crud_payment_due: false,
  crud_payment_due: false,

  loading_payments_loans_by_customer: false,
  payments_loans_by_customer: null,

}

const loanReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CRUD_LOAN: {
      return {
        ...state,
        loading_crud_loan: action.payload.loading,
        crud_loan: action.payload.crud,
      }
    }
    case Actions.GET_LOANS_CUSTOMERS: {
      return {
        ...state,
        loans_customers: action.payload,
      }
    }
    case Actions.GET_LOANS_BY_CUSTOMER: {
      return {
        ...state,
        loading_loans_by_customer: action.payload.loading,
        loans_by_customer: action.payload.data,
      }
    }
    case Actions.CRUD_PAYMENT_DUE: {
      return {
        ...state,
        loading_crud_payment_due: action.payload.loading,
        crud_payment_due: action.payload.crud,
      }
    }
    case Actions.GET_PAYMENTS_LOANS_BY_CUSTOMER: {
      return {
        ...state,
        loading_payments_loans_by_customer: action.payload.loading,
        payments_loans_by_customer: action.payload.data,
      }
    }
    default: {
      return state
    }
  }
}

export default loanReducer
