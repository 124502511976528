import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_ALL_PRODUCTS = "[PRODUCTS] GET ALL PRODUCTS"
export const LOADING_GET_PRODUCTS = "[PRODUCTS] LOADING GET PRODUCTS"
export const GET_PRODUCTS = "[PRODUCTS] GET PRODUCTS"
export const GET_PRODUCTS_BY_FILTER = "[PRODUCTS] GET PRODUCTS BY FILTER"
export const GET_ALL_SORTS = "[PRODUCTS] GET ALL SORTS"
export const GET_ALL_SUBSORTS = "[PRODUCTS] GET ALL SUBSORTS"
export const GET_ALL_BRANDS = "[PRODUCTS] GET ALL BRANDS"
export const GET_ALL_MATERIALS = "[PRODUCTS] GET ALL MATERIALS"
export const GET_ALL_PRESENTATIONS = "[PRODUCTS] GET ALL PRESENTATIONS"
export const GET_ALL_TYPEEXISTENCES = "[PRODUCTS] GET ALL TYPEEXISTENCES"
export const GET_ALL_UNITMEASURES = "[PRODUCTS] GET ALL UNITMEASURES"
export const GET_ALL_UNIT_MEASURES_BY_PRODUCT = "[PRODUCTS] GET ALL UNIT MEASURES BY PRODUCT"
export const GET_DATA_PRODUCT = "[PRODUCTS] GET DATA PRODUCT"
export const GET_LAST_COST_PRICE = "[PRODUCTS] GET LAST COST PRICE"
export const STOCK_RODUCT = "[PRODUCTS] STOCK_RODUCT"
export const CRUD_PRODUCT = "[PRODUCTS] CRUD PRODUCT"
export const CRUD_SORT = "[PRODUCTS] CRUD SORT"
export const CRUD_SUBSORT = "[PRODUCTS] CRUD SUBSORT"
export const CRUD_UNITMEASURES = "[PRODUCTS] CRUD UNITMEASURES"
export const CRUD_BRAND = "[PRODUCTS] CRUD BRAND"
export const CRUD_MATERIAL = "[PRODUCTS] CRUD MATERIAL"
export const CRUD_PRESENTATION = "[PRODUCTS] CRUD PRESENTATION"
export const CRUD_TYPEEXISTENCE = "[PRODUCTS] CRUD TYPEEXISTENCE"
export const CRUD_UNIT_MEASURES_BY_PRODUCT = "[PRODUCTS] CRUD UNIT MEASURES BY PRODUCT"
export const GET_COMPLEMENTS_PRODUCT = "[PRODUCTS] GET COMPLEMENTS PRODUCT"
export const SAVE_COMPLEMENT_PRODUCT = "[PRODUCTS] SAVE COMPLEMENT PRODUCT"
export const DELETE_COMPLEMENT_PRODUCT = "[PRODUCTS] DELETE COMPLEMENT PRODUCT"
export const GET_PRODUCT_DETAILS = "[PRODUCTS] GET PRODUCT DETAILS"

export function deleteUnitMeasuresByProduct(form) {
  toast.info("Eliminando medida...", { toastId: "deleteUnitMeasureByProduct", autoClose: 20000, })
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eumproducto`, form)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje, {
          toastId: "deleteUnitMeasureByProduct",
          autoClose: 7000,
        })
      } else {
        toast.error(response.data.mensaje, {
          toastId: "deleteUnitMeasureByProduct",
          autoClose: 7000,
        })
      }
      dispatch({ type: CRUD_UNIT_MEASURES_BY_PRODUCT, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_UNIT_MEASURES_BY_PRODUCT, payload: false })
      }, 50)
    })
}

export function updateAllUnitMeasuresByProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/aumproducto`, form)
  return (dispatch) => {
    toast.info("Actualizando unidad de medida...", { toastId: "updateAllUnitMeasureByProduct", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje, { toastId: "updateAllUnitMeasureByProduct" })
      } else {
        toast.error(response.data.mensaje, { toastId: "updateAllUnitMeasureByProduct" })
      }
      dispatch({ type: CRUD_UNIT_MEASURES_BY_PRODUCT, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_UNIT_MEASURES_BY_PRODUCT, payload: false })
      }, 50)
    })
  }
}

export function getAllUnitMeasuresByProduct(from, showLoading = false) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/umproducto?producto_id=${from}`)
  return (dispatch) => {
    if (showLoading) {
      toast.info("Obteniendo unidades de medida...", { toastId: "getUmsProduct", autoClose: false })
    }
    dispatch({ type: GET_ALL_UNIT_MEASURES_BY_PRODUCT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.dismiss("getUmsProduct")
        dispatch({ type: GET_ALL_UNIT_MEASURES_BY_PRODUCT, payload: { loading: false, data: response.data.detalles } })
      } else {
        toast.error(response.data.mensaje, { toastId: "getUmsProduct", autoClose: 5000 })
        dispatch({ type: GET_ALL_UNIT_MEASURES_BY_PRODUCT, payload: { loading: false, data: null } })
      }
      setTimeout(() => {
        dispatch({ type: GET_ALL_UNIT_MEASURES_BY_PRODUCT, payload: { loading: false, data: null } })
      }, 50)
      return
    }).catch((error) => {
      console.log(GET_ALL_UNIT_MEASURES_BY_PRODUCT, error)
      return dispatch({ type: GET_ALL_UNIT_MEASURES_BY_PRODUCT, payload: { loading: false, data: null } })
    })
  }
}

export function getAllProducts() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/producto`)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_ALL_PRODUCTS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getProductsWithLimit(limit = null, filter = "") {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/productospaginado?limit=${limit}&filtro=${filter}`)
  return dispatch => {
    dispatch({ type: LOADING_GET_PRODUCTS, payload: true })
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PRODUCTS, payload: response.data })
      dispatch({ type: LOADING_GET_PRODUCTS, payload: false })
      return
    }).catch(error => {
      dispatch({ type: LOADING_GET_PRODUCTS, payload: false })
    })
  }
}

export function getAllSorts() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/clase`)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_ALL_SORTS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getAllSubSorts() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/subclase`)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_ALL_SUBSORTS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getAllBrands() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/marca`)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_ALL_BRANDS,
        payload: response.data.detalles,
      })
    })
}

export function getAllMaterials() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/material`)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          //
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_ALL_MATERIALS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getAllPresentation() {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/presentacion`
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_ALL_PRESENTATIONS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getAllTypeExistences() {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/tipoexistencia`
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_ALL_TYPEEXISTENCES,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getAllUnitMeasures() {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/unidadmedida`
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_ALL_UNITMEASURES,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getLastCostPriceByProduct(idProduct, umId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ultimocosto/${idProduct}/${umId}`)
  return (dispatch) => {
    dispatch({ type: GET_LAST_COST_PRICE, payload: { loading: true, data: null } })
    toast.info("Obteniendo ultimo precio de costo...", { toastId: "getLastCostPrice", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.dismiss("getLastCostPrice")
        dispatch({ type: GET_LAST_COST_PRICE, payload: { loading: false, data: response.data.detalles } })
      } else {
        dispatch({ type: GET_LAST_COST_PRICE, payload: { loading: false, data: null } })
        toast.update("getLastCostPrice", { render: response.data.mensaje, type: "error", autoClose: 4000 })
      }
      setTimeout(() => {
        dispatch({ type: GET_LAST_COST_PRICE, payload: { loading: false, data: null } })
      }, 50)
    })
  }
}

export function saveUnitMeasureProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rumproducto`, form)
  return (dispatch) => {
    toast.info("Guardando unidad de medida...", { toastId: "saveUnitMeasureByProduct", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_UNIT_MEASURES_BY_PRODUCT, payload: true })
        toast.success(response.data.mensaje, { toastId: "saveUnitMeasureByProduct" })
      } else {
        toast.error(response.data.mensaje, { toastId: "saveUnitMeasureByProduct", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_UNIT_MEASURES_BY_PRODUCT, payload: false })
      }, 50)

    }).catch((err) => {
      toast.error(err, { toastId: "saveUnitMeasureByProduct", autoClose: 5000 })
    })
  }
}

export function saveProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rproducto`, form)
  return (dispatch) => {
    toast.info("Guardando producto", { toastId: "saveProduct", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PRODUCT, payload: true })
        toast.update("saveProduct", { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update("saveProduct", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PRODUCT, payload: false })
      }, 50)
    }).catch((err) => {
      toast.error("Error al guardar el producto", { toastId: "saveProduct", autoClose: 5000, })
      console.log(CRUD_PRODUCT, err)
    })
  }
}

export function updateProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/aproducto`, form)
  return (dispatch) => {
    toast.info("Actualizando producto...", { toastId: "updateProduct", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PRODUCT, payload: true })
        toast.update("updateProduct", { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update("updateProduct", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_PRODUCT, payload: false })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_PRODUCT, payload: false })
      toast.error("Error al actualizar el producto", { toastId: "updateProduct", autoClose: 5000, })
      console.log(CRUD_PRODUCT, err)
    })
  }
}

export function updateStatusByProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cproducto`, form)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PRODUCT, payload: true })
        form.estado == 1
          ? toast.success(response.data.mensaje, { toastId: "updateStatusByProduct" })
          : toast.error(response.data.mensaje, { toastId: "updateStatusByProduct" })
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PRODUCT, payload: false })
      }, 50)
    }).catch((err) => {
      toast.update("deleteProduct", { render: "Error al actualizar el estado del producto", type: "error", autoClose: 5000 })
      console.log(CRUD_PRODUCT, err)
    })
  }
}

export function deleteProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eproducto`, form)
  return (dispatch) => {
    toast.info("Eliminando producto...", { toastId: "deleteProduct", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PRODUCT, payload: true })
        toast.update("deleteProduct", { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update("deleteProduct", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PRODUCT, payload: false })
      }, 50)
    }).catch((err) => {
      toast.update("deleteProduct", { render: "Error al eliminar el producto", type: "error", autoClose: 5000 })
      console.log(CRUD_PRODUCT, err)
    })
  }
}

export function uploadProductImage(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rimagenproducto`, form)
  return (dispatch) => {
    toast.info("Subiendo imagen del producto...", { toastId: "uploadProductImage", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PRODUCT, payload: true })
        toast.update("uploadProductImage", { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update("uploadProductImage", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PRODUCT, payload: false })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_PRODUCT, payload: false })
      toast.update("uploadProductImage", { render: "Error al subir la imagen del producto", type: "error", autoClose: 5000 })
      console.log(CRUD_PRODUCT, err)
    })
  }
}

export function uploadProductFile(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rfichatecnicaproducto`, form)
  return (dispatch) => {
    toast.info("Subiendo archivo del producto...", { toastId: "uploadProductFile", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PRODUCT, payload: true })
        toast.update("uploadProductFile", { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update("uploadProductFile", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PRODUCT, payload: false })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_PRODUCT, payload: false })
      toast.update("uploadProductFile", { render: "Error al subir el archivo del producto", type: "error", autoClose: 5000 })
      console.log(CRUD_PRODUCT, err)
    })
  }
}

export function getProductDetails(productId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gdetallesproducto/${productId}`)
  return (dispatch) => {
    dispatch({ type: GET_PRODUCT_DETAILS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PRODUCT_DETAILS, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_PRODUCT_DETAILS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: GET_PRODUCT_DETAILS, payload: { loading: false, data: null } })
    })
  }
}

export function getStockOfProduct(productId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gstockproducto/${productId}`)
  return (dispatch) => {
    dispatch({ type: STOCK_RODUCT, payload: { loading: true, stock: null } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: STOCK_RODUCT, payload: { loading: false, stock: response.data.detalles } })
    })
  }
}

export function deleteSort(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eclase`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_SORT, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_SORT, payload: false })
      }, 50)
    })
}

export function deleteSubSort(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/esubclase`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_SUBSORT, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_SUBSORT, payload: false })
      }, 50)
    })
}

export function saveSort(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/rclase`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_SORT, payload: true, })
      setTimeout(() => {
        dispatch({ type: CRUD_SORT, payload: false })
      }, 50)
    })
}

export function saveSubSort(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rsubclase`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        getAllSubSorts()
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_SUBSORT, payload: true, })
      setTimeout(() => {
        dispatch({ type: CRUD_SUBSORT, payload: false })
      }, 50)
    })
}

export function updateSort(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/aclase`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_SORT, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_SORT, payload: false })
      }, 50)
    })
}

export function updateSubSort(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/asubclase`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_SUBSORT, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_SUBSORT, payload: false })
      }, 50)
    })
}

export function deleteUnitMeasure(form) {
  toast.info("Eliminando medida...", {
    toastId: "deleteUnitMeasure",
    autoClose: 20000,
  })
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eunidadmedida`, form)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje, {
          toastId: "deleteUnitMeasure",
          autoClose: 7000,
        })
      } else {
        toast.error(response.data.mensaje, {
          toastId: "deleteUnitMeasure",
          autoClose: 7000,
        })
      }

      dispatch({ type: CRUD_UNITMEASURES, payload: true, })
      setTimeout(() => {
        dispatch({ type: CRUD_UNITMEASURES, payload: false })
      }, 50)
    })
}

export function deleteBrand(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/emarca`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_BRAND, payload: true, })
      setTimeout(() => {
        dispatch({ type: CRUD_BRAND, payload: false })
      }, 50)
    })
}

export function saveBrand(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rmarca`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_BRAND, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_BRAND, payload: false })
      }, 50)
    })
}
export function updateBrand(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/amarca`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_BRAND, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_BRAND, payload: false })
      }, 50)
    })
}

export function updateStatusByBrand(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cmarca`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        parseInt(form.estado) === 1
          ? toast.success(response.data.mensaje)
          : toast.error(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      dispatch({ type: CRUD_BRAND, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_BRAND, payload: false })
      }, 50)
    })
}

export function deleteMaterial(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/ematerial`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_MATERIAL, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_MATERIAL, payload: false })
      }, 50)
    })
}

export function deletePresentation(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/epresentacion`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_PRESENTATION, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_PRESENTATION, payload: false })
      }, 50)
    })
}

export function deleteTypeExistence(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/etipoexistencia`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_TYPEEXISTENCE, payload: true })

      setTimeout(() => {
        dispatch({ type: CRUD_TYPEEXISTENCE, payload: false })
      }, 50)
    })
}

export function saveUnitMeasure(form) {
  toast.info("Guardando medida...", {
    toastId: "saveUnitMeasure",
    autoClose: 20000,
  })
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/runidadmedida`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje, {
          toastId: "saveUnitMeasure",
          autoClose: 20000,
        })
      } else {
        toast.error(response.data.mensaje, {
          toastId: "saveUnitMeasure",
          autoClose: 20000,
        })
      }

      dispatch({ type: CRUD_UNITMEASURES, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_UNITMEASURES, payload: false })
      }, 50)
    })
}

export function saveMaterial(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/rmaterial`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_MATERIAL, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_MATERIAL, payload: false })
      }, 50)
    })
}

export function savePresentation(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/rpresentacion`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_PRESENTATION, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_PRESENTATION, payload: false })
      }, 50)
    })
}

export function saveTypeExistence(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/rtipoexistencia`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_TYPEEXISTENCE, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_TYPEEXISTENCE, payload: false })
      }, 50)
    })
}

export function updateUnitMeasure(form) {
  toast.info("Actualizando medida...", {
    toastId: "updateUnitMeasure",
    autoClose: 20000,
  })
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/aunidadmedida`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje, {
          toastId: "updateUnitMeasure",
          autoClose: 7000,
        })
      } else {
        toast.error(response.data.mensaje, {
          toastId: "updateUnitMeasure",
          autoClose: 7000,
        })
      }

      dispatch({ type: CRUD_UNITMEASURES, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_UNITMEASURES, payload: false })
      }, 50)
    })
}

export function updateMaterial(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/amaterial`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_MATERIAL, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_MATERIAL, payload: false })
      }, 50)
    })
}

export function updatePresentation(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/apresentacion`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_PRESENTATION, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_PRESENTATION, payload: false })
      }, 50)
    })
}

export function updateTypeExistence(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/atipoexistencia`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_TYPEEXISTENCE, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_TYPEEXISTENCE, payload: false })
      }, 50)
    })
}

export function updateStatusBySort(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cclase`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        parseInt(form.estado) === 1
          ? toast.success(response.data.mensaje)
          : toast.error(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      dispatch({ type: CRUD_SORT, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_SORT, payload: false })
      }, 50)
    })
}

export function updateStatusBySubSort(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/csubclase`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        parseInt(form.estado) === 1
          ? toast.success(response.data.mensaje)
          : toast.error(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_SUBSORT, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_SUBSORT, payload: false })
      }, 50)
    })
}

export function updateStatusByUnitMeasure(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cunidadmedida`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        parseInt(form.estado) === 1
          ? toast.success(response.data.mensaje)
          : toast.error(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_UNITMEASURES, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_UNITMEASURES, payload: false })
      }, 50)
    })
}

export function updateStatusByMaterial(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cmaterial`, form)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        parseInt(form.estado) === 1
          ? toast.success(response.data.mensaje)
          : toast.error(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_MATERIAL, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_MATERIAL, payload: false })
      }, 50)
    })
}

export function updateStatusByPresentation(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpresentacion`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        parseInt(form.estado) === 1
          ? toast.success(response.data.mensaje)
          : toast.error(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_PRESENTATION, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_PRESENTATION, payload: false })
      }, 50)
    })
}

export function updateStatusByTypeExistence(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/ctipoexistencia`,
    form
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        parseInt(form.estado) === 1
          ? toast.success(response.data.mensaje)
          : toast.error(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_TYPEEXISTENCE, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_TYPEEXISTENCE, payload: false })
      }, 50)
    })
}

export function getComplementsProduct(producId, useLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gpcomplementos/${producId}`)
  return (dispatch) => {
    if (useLoading) {
      dispatch({ type: GET_COMPLEMENTS_PRODUCT, payload: { loading: true } })
    }
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_COMPLEMENTS_PRODUCT, payload: { loading: false, data: response.data.detalles } })
      } else {
      }
      setTimeout(() => {
        dispatch({ type: GET_COMPLEMENTS_PRODUCT, payload: { loading: false, data: null } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: GET_COMPLEMENTS_PRODUCT, payload: { loading: false, data: null } })
      toast.error("Error al obtener complementos del producto")
    })
  }
}

export function saveComplementProduct(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rpcomplemento`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_COMPLEMENT_PRODUCT, payload: { loading: true } })
    toast.info("Guardando complemento...", { toastId: "saveComplementProduct", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: SAVE_COMPLEMENT_PRODUCT, payload: { loading: false, saved: true } })
        toast.success(response.data.mensaje, { toastId: "saveComplementProduct", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "saveComplementProduct", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: SAVE_COMPLEMENT_PRODUCT, payload: { loading: false, saved: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: SAVE_COMPLEMENT_PRODUCT, payload: { loading: false, saved: false } })
      toast.error(err, { toastId: "saveComplementProduct", autoClose: 5000 })
    })
  }
}

export function deleteComplementProduct(complementId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/dpcomplemento/${complementId}`)
  return (dispatch) => {
    dispatch({ type: DELETE_COMPLEMENT_PRODUCT, payload: { loading: true } })
    toast.info("Eliminando complemento...", { toastId: "deleteComplementProduct", autoClose: false, })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: DELETE_COMPLEMENT_PRODUCT, payload: { loading: false, deleted: true } })
        toast.success(response.data.mensaje, { toastId: "deleteComplementProduct", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "deleteComplementProduct", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: DELETE_COMPLEMENT_PRODUCT, payload: { loading: false, deleted: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: DELETE_COMPLEMENT_PRODUCT, payload: { loading: false, deleted: false } })
      toast.error(err, { toastId: "deleteComplementProduct", autoClose: 5000 })
    })
  }
}