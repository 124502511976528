import React from "react"
import axios from "axios"
import InfoNoStockProduct from "components/pages/helpers/info-no-stock-product/InfoNoStockProduct"
import * as Actions from "components/auth/store/actions"
import { toast } from "react-toastify"
import { toast as reactHotToast } from 'react-hot-toast'
import { urlPdfs } from 'constants/index'
import { sendSale } from "./manage_cpe.actions"

export const GET_DATA_TYPE_PAYMENT = "[SALES] GET ALL TYPE PAYMENT"
export const GET_DATA_TYPE_PAYMENT_FROMS = "[SALES] GET ALL TYPE PAYMENT FROMS"
export const GET_DATA_SERIES_VOUCHER = "[SALES] GET ALL DATA SERIES BY VOUCHER"
export const GET_DATA_CORRELATIVE_SERIES_VOUCHER = "[SALES] GET DATA CORRELATIVE SERIES BY VOUCHER"
export const GET_DATA_COIN_TYPE = "[SALES] GET DATA COIN TYPE"
export const LOADING_SALES_LIST = "[SALES] LOADING SALES LIST"
export const GET_SALES_LIST = "[SALES] GET SALES LIST"
export const GET_SALES_BY_DISPATCH = "[SALES] GET SALES BY DISPATCH"
export const SET_TOTAL_PAGES_SALES_LIST = "[SALES] SET TOTAL PAGES SALES LIST"
export const GET_SALES_INFO = "[SALES] GET SALES INFO"
export const CRUD_SALES = "[SALES] CRUD SALES"
export const GET_PRICE_DOLLAR_SALES = "[SALES] GET PRICE DOLLAR"
export const GET_DATA_RESERVATION_RESIDENCE = "[SALES] GET ALL RESERVATION RESIDENCE"
export const GET_DATA_RESERVATION_RESIDENCE_CONSUMPTION_STAY = "[SALES] GET DATA RESERVATION RESIDENCE CONSUMPTION STAY"
export const GET_REPORT_SALES = "[SALES] GET REPORT SALES"
export const SAVE_SALE = "[SALES] SAVE SALE"
export const SERIES_PRODUCTS_ON_SALE = "[SALES] SERIES PRODUCTS ON SALE"

export const GET_CREDITS_BY_PERSON = "[ACCOUNTS RECEIVABLE] GET SALE CREDIT"
export const SALE_CREDIT_TRUE = "[ACCOUNTS RECEIVABLE] SALE CREDIT TRUE"
export const SAVE_CREDIT = "[ACCOUNTS RECEIVABLE] SAVE CREDIT"
export const RESET_CRUD_CREDIT = "[ACCOUNTS RECEIVABLE] RESET CRUD CREDIT"
export const GET_PERSONS_CREDITS = "[ACCOUNTS RECEIVABLE] GET PERSONS CREDITS"
export const SAVE_PAY_OF_CREDIT = "[ACCOUNTS RECEIVABLE] SAVE PAY OF CREDIT"
export const RESET_CRUD_PAY_OF_CREDIT = "[ACCOUNTS RECEIVABLE] RESET CRUD PAY OF CREDIT"
export const GET_PAYMENTS_PERSON_OF_CREDIT = "[ACCOUNTS RECEIVABLE] GET PAYMENTS PERSON OF CREDIT"
export const LOADING_PP_OF_CREDIT = "[ACCOUNTS RECEIVABLE] LOADING_PP_OF_CREDIT"
export const CANCEL_PAY_OF_CREDIT = "[ACCOUNTS RECEIVABLE] CANCEL PAY OF CREDIT"
export const CANCEL_CREDIT = "[ACCOUNTS RECEIVABLE] CANCEL CREDIT"

export function getAllSales({ page = 1, userId = 0, branchId = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/facturacion?page=${page}&idusuario=${userId}&idsucursal=${branchId}`
  )
  return (dispatch) => {
    dispatch({ type: LOADING_SALES_LIST, payload: true })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SALES_LIST, payload: response.data })
      dispatch({ type: LOADING_SALES_LIST, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: LOADING_SALES_LIST, payload: false })
    })
  }
}

export function getAllSalesByDispatch(page = 1, state = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ventasdespachar?page=${page}&estado=${state}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SALES_BY_DISPATCH, payload: response.data })
    }).catch((error) => {
      dispatch({ type: GET_SALES_BY_DISPATCH, payload: [] })
      toast.error("Error al obtener los registros")
      console.log(error)
    })
}

export function updateSaleDispatch(form, state) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/uventadespacho`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_SALE, payload: { loading: true } })
    toast.info("Actualizando...", { toastId: "updateSaleDispatch", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch(getAllSalesByDispatch(1, state))
        toast.success(response.data.mensaje, { toastId: "updateSaleDispatch", autoClose: 4000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "updateSaleDispatch", autoClose: 5000 })
      }
    }).catch((error) => {
      toast.error("Error al actualizar los datos", { toastId: "updateSaleDispatch", autoClose: 5000 })
      console.log(error)
    })
  }
}

export function getInfoSales(saleId, useLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/facturadetalle/${saleId}`)
  return (dispatch) => {
    if (useLoading) {
      toast.info("Obteniendo venta...", { toastId: "getInfoSales", autoClose: false })
      dispatch({ type: GET_SALES_INFO, payload: { loading: true } })
    }
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_SALES_INFO, payload: { loading: false, data: response.data.detalles } })
        if (useLoading) {
          toast.dismiss("getInfoSales")
        }
      } else {
        toast.update('getInfoSales', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        return dispatch({ type: GET_SALES_INFO, payload: { loading: false, data: null } })
      }, 50)
    })
  }
}

export function saveSales(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rfacturacion`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_SALE, payload: { loading: true } })
    toast.info("Guardando venta...", { toastId: "saveSale", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        sendSale({ idventa: response.data.detalles.facturacion_id, refresh: false })

        dispatch({ type: SAVE_SALE, payload: { saved: true, loading: false } })
        toast.update('saveSale', { render: response.data.mensaje, type: "success", autoClose: 5000 })

        if (response.data.detalles.tipo_pago_id == 2) { // 2 es el id del tipo de pago CREDITO 

          dispatch({
            type: SALE_CREDIT_TRUE,
            payload: { ...response.data.detalles, a4: form.facturacion.a4, ticket: form.facturacion.ticket }
          })

        } else {

          if (response.data.detalles.con_series) {
            dispatch({ type: SERIES_PRODUCTS_ON_SALE, payload: response.data.detalles })
          } else {
            if (response.data.detalles.aplica_despacho == 1) {
              toast.update('saveSale', {
                render: "Se guardó la venta con éxito, imprima la venta después del despacho",
                type: "success",
                autoClose: 7000
              })
            } else {
              if (form.facturacion.ticket) {
                window.open(`${urlPdfs.ventaTicket}/${response.data.detalles.facturacion_id}`, "_blank")
              }
              if (form.facturacion.a4) {
                window.open(`${urlPdfs.ventaA4}/${response.data.detalles.facturacion_id}`, "_blank")
              }
            }
          }

        }
      } else {
        if (response.data.info === "no_stock") { // si no hay stock de algun producto
          toast.dismiss('saveSale')
          let msg = response.data.mensaje.split('|')
          reactHotToast.custom(<InfoNoStockProduct msg={msg} />, { duration: 10000 })
        } else {
          toast.update('saveSale', { render: response.data.mensaje, type: "error", autoClose: 5000 })
        }
      }
      setTimeout(() => {
        dispatch({ type: SAVE_SALE, payload: { saved: false, loading: false } })
        dispatch({ type: SERIES_PRODUCTS_ON_SALE, payload: null })
      }, 50)
    }).catch((error) => {
      toast.update('saveSale', { render: "Ocurrió un error inesperado...", type: "error", autoClose: 5000 })
      dispatch({ type: SAVE_SALE, payload: { saved: false, loading: false } })
      console.log(SAVE_SALE, error)
    })
  }
}

export function updateSales(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/afacturacion`, form)
  return (dispatch) => {
    toast.info("Actualizando venta...", { toastId: "updateSales", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.update('updateSales', { render: response.data.mensaje, type: "success", autoClose: 5000 })
        if (response.data.detalles.tipo_pago_id == 2) { // 2 es el id del tipo de pago CREDITO 
          dispatch({
            type: SALE_CREDIT_TRUE,
            payload: response.data.detalles,
          })
        }
      } else {
        if (response.data.info === "no_stock") { // si no hay stock de algun producto
          toast.dismiss('updateSales')
          let msg = response.data.mensaje.split('|')
          reactHotToast.custom(<InfoNoStockProduct msg={msg} />, { duration: 10000 })
        } else {
          toast.update('updateSales', { render: response.data.mensaje, type: "error", autoClose: 5000 })
        }
      }
      dispatch({ type: CRUD_SALES, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_SALES, payload: false })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SALES, payload: false })
      toast.update('updateSales', { render: "Ocurrió un error inesperado...", type: "error", autoClose: 5000 })
      console.log(CRUD_SALES, error)
    })
  }
}

export function updateStatusBySales(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cfacturacion`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        form.estado_venta === 1
          ? toast.success(response.data.mensaje)
          : toast.error(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_SALES, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_SALES, payload: false })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_SALES, error)
      dispatch({ type: CRUD_SALES, payload: false })
    })
}

export function getReportSales(form) {
  if (!form) return (dispatch) => dispatch({ type: GET_REPORT_SALES, payload: null })
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/reporte_ventas`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({
        type: GET_REPORT_SALES,
        payload: response.data.detalles ? response.data.detalles : null,
      })
    })
}

export function getPriceDollar() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/preciodollar`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_PRICE_DOLLAR_SALES,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function deleteSales(idSales) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/dfacturacion/${idSales}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        console.log(response.data)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CRUD_SALES, payload: true })
      setTimeout(() => {
        dispatch({ type: CRUD_SALES, payload: false })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_SALES, error)
      dispatch({ type: CRUD_SALES, payload: false })
    })
}

export function getAllCoinType() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tipomoneda`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_COIN_TYPE,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getCorrelativeSeriesVoucher(form) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/seriexcomprobantexusuario/${form.idUser}/${form.idVoucher}/${form.idSerie}`
  )
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({
        type: GET_DATA_CORRELATIVE_SERIES_VOUCHER,
        payload: response.data.detalles === null ? {} : response.data.detalles,
      })
      setTimeout(() => {
        dispatch({ type: GET_DATA_CORRELATIVE_SERIES_VOUCHER, payload: {} })
      }, 50)
    })
}

export function getAllSeriesVoucher(form) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/seriexcomprobante/${form.idUser}/${form.idVoucher}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_SERIES_VOUCHER,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getAllTypesPayments() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tipopago`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_TYPE_PAYMENT,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getAllTypesPaymentsFroms(form) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tipoformapago/${form}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_TYPE_PAYMENT_FROMS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getAllReservationResidence() {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/creservaestancialist`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_RESERVATION_RESIDENCE,
        payload:
          response.data.detalles.reserva === null
            ? []
            : response.data.detalles.reserva,
      })
    })
}

export function getAllReservationResidenceConsumptionStay(form) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/cconsumopagolist/${form}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_RESERVATION_RESIDENCE_CONSUMPTION_STAY,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function saveCredit(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcredito`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje, { toastId: "saveCredit", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({
        type: SAVE_CREDIT,
        payload: response.data.detalles ? response.data.detalles : null,
      })

      setTimeout(() => {
        dispatch({ type: RESET_CRUD_CREDIT })
      }, 50)
    })
}

export function getPersonsWithCredits(userId = 0) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gpersonascreditos/${userId}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_PERSONS_CREDITS, payload: response.data.detalles })
    })
}

export function getCreditsByPersonId(personId, userId = 0) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gcreditospersona?idpersona=${personId}&idusuario=${userId}`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_CREDITS_BY_PERSON, payload: response.data.detalles })
    })
  }
}

export function savePayOfCredit(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcpago`, form)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        window.open(`${urlPdfs.notaPagoTicket}/${response.data.detalles.idpago}`, "_blank")

      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({
        type: SAVE_PAY_OF_CREDIT,
        payload: response.data.detalles ? response.data.detalles : null,
      })

      setTimeout(() => {
        dispatch({
          type: RESET_CRUD_PAY_OF_CREDIT,
        })
      }, 50)
    })
}

export function getPaymentsPersonOfCredit(personId, userId, useLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gcpagospersona?idpersona=${personId}&idusuario=${userId}`)
  return (dispatch) => {
    if (useLoading) dispatch({ type: LOADING_PP_OF_CREDIT, payload: true })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_PAYMENTS_PERSON_OF_CREDIT, payload: response.data.detalles })
    })
  }
}

export function cancelPayOfCredit(payId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ccpago/${payId}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }

      dispatch({ type: CANCEL_PAY_OF_CREDIT, payload: true })

      setTimeout(() => {
        dispatch({ type: CANCEL_PAY_OF_CREDIT, payload: false })
      }, 50)

    })
}

export function cancelCredit(creditId) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/ccredito/${creditId}`
  )

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje, { autoClose: 5000 })
      }

      dispatch({ type: CANCEL_CREDIT, payload: true })

      setTimeout(() => {
        dispatch({ type: CANCEL_CREDIT, payload: false })
      }, 50)

    })
}