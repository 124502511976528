import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { urlPdfs } from "constants/index"

export const CRUD_LOAN = "[LOAN] CRUD LOAN"
export const GET_LOANS_CUSTOMERS = "[LOAN] GET LOANS CUSTOMERS"
export const GET_LOANS_BY_CUSTOMER = "[LOAN] GET LOANS BY CUSTOMER"

export const CRUD_PAYMENT_DUE = "[PAYMENTS DUES] CRUD PAYMENT DUE"
export const GET_PAYMENTS_LOANS_BY_CUSTOMER = "[PAYMENTS LOANS] GET PAYMENTS LOANS BY CUSTOMER"

export function saveLoan(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rprestamo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN, payload: { loading: true } })
    toast.info('Registrando prestamo...', { toastId: 'saveLoan', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: true } })
        toast.update('saveLoan', { render: response.data.mensaje, type: "success", autoClose: 5000 })
        window.open(`${urlPdfs.prestamoTicket}/${response.data.detalles.id}`, "_blank")
      } else {
        toast.update('saveLoan', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('saveLoan', { render: "Error inesperado. No se pudo registrar el prestamo", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deleteLoan(loanId) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eprestamo?idprestamo=${loanId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN, payload: { loading: true } })
    toast.info('Anulando prestamo...', { toastId: 'deleteLoan', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: true } })
        toast.update('deleteLoan', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('deleteLoan', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('deleteLoan', { render: "Error inesperado. No se pudo anular el prestamo", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_LOAN, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getLoansCustomers() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/prestamosclientes`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_LOANS_CUSTOMERS, payload: response.data.detalles })
    })
  }
}

export function getLoansByCustomer(customerId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/prestamoscliente?idpersona=${customerId}`)
  return (dispatch) => {
    dispatch({ type: GET_LOANS_BY_CUSTOMER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_LOANS_BY_CUSTOMER, payload: { loading: false, data: response.data.detalles } })
        toast.update('saveLoan', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('saveLoan', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: GET_LOANS_BY_CUSTOMER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      toast.update('saveLoan', { render: "Error inesperado. No se pudo obtener los prestamos del cliente", type: "error", autoClose: 5000 })
      dispatch({ type: GET_LOANS_BY_CUSTOMER, payload: { loading: false, data: null } })
      console.log(error)
    })
  }
}

export function savePaymentDue(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rpagocuotaprestamo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: true } })
    toast.info('Registrando pago...', { toastId: 'savePaymentDue', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: true } })
        toast.update('savePaymentDue', { render: response.data.mensaje, type: "success", autoClose: 5000 })
        window.open(`${urlPdfs.pagoCuotaPrestamoTicket}/${response.data.detalles.id}`, "_blank")
      } else {
        toast.update('savePaymentDue', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('savePaymentDue', { render: "Error inesperado. No se pudo registrar el pago", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deletePaymentDue(paymentId) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/epagocuotaprestamo?idpago=${paymentId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: true } })
    toast.info('Anulando pago...', { toastId: 'deletePaymentDue', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: true } })
        toast.update('deletePaymentDue', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('deletePaymentDue', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('deletePaymentDue', { render: "Error inesperado. No se pudo anular el pago", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_PAYMENT_DUE, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getPaymentsLoansByCustomer(customerId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gpagoscuotasprestamos?idpersona=${customerId}`)
  return (dispatch) => {
    dispatch({ type: GET_PAYMENTS_LOANS_BY_CUSTOMER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_PAYMENTS_LOANS_BY_CUSTOMER, payload: { loading: false, data: response.data.detalles } })
        toast.update('saveLoan', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('saveLoan', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: GET_PAYMENTS_LOANS_BY_CUSTOMER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      toast.update('saveLoan', { render: "Error inesperado. No se pudo obtener los pagos del cliente", type: "error", autoClose: 5000 })
      dispatch({ type: GET_PAYMENTS_LOANS_BY_CUSTOMER, payload: { loading: false, data: null } })
      console.log(error)
    })
  }
}