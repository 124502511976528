import axios from "axios"
import { toast } from 'react-toastify'
import * as Actionss from 'components/auth/store/actions'

import { sendCreditNote } from "./manage_cpe.actions"

export const GET_LIST_DISCREPANCIES = "[CREDIT_NOTE] GET LIST DISCREPANCIES"
export const LOADING_LIST_CREDIT_NOTE = "[CREDIT_NOTE] LOADING LIST CREDIT NOTE"
export const GET_LIST_CREDIT_NOTE = "[CREDIT_NOTE] GET LIST CREDIT NOTE"
export const SAVE_CREDIT_NOTE = "[CREDIT_NOTE] SAVE CREDIT NOTE"

export function getDiscrepancies() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tdiscrepancias`)
  return dispatch =>
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_LIST_DISCREPANCIES,
        payload: response.data.detalles === null ? [] : response.data.detalles
      })
    }
    )
}

export function listCreditNote({ page = 1, userId = 0, branchId = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/notacredito?page=${page}&idusuario=${userId}&idsucursal=${branchId}`
  )
  return dispatch => {
    dispatch({ type: LOADING_LIST_CREDIT_NOTE, payload: true })
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      dispatch({ type: GET_LIST_CREDIT_NOTE, payload: response.data })
      dispatch({ type: LOADING_LIST_CREDIT_NOTE, payload: false })
      return
    }).catch(error => {
      console.log(error)
      dispatch({ type: LOADING_LIST_CREDIT_NOTE, payload: false })
    })
  }
}

export function saveCreditNote(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rnotacredito`, form)
  return dispatch => {
    dispatch({ type: SAVE_CREDIT_NOTE, payload: { saved: false, loading: true } })
    toast.info("Guardando nota de crédito...", { toastId: "saveCreditNote", autoClose: false })
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        sendCreditNote({ idnota: response.data.detalles.nota_id, refresh: false })
        dispatch({ type: SAVE_CREDIT_NOTE, payload: { saved: true, loading: false } })
        toast.update("saveCreditNote", { render: response.data.mensaje, type: "success", autoClose: 1500 })
        window.open(
          `${process.env.REACT_APP_API_URL}/api/pdfnotacredito/${response.data.detalles.nota_id}`,
          "_blank"
        )
      } else {
        toast.update("saveCreditNote", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: SAVE_CREDIT_NOTE, payload: { saved: false, loading: false } })
      }, 50)
    }).catch((error) => {
      console.log(error)
      toast.update("saveCreditNote", { render: error.message, type: "error", autoClose: 5000 })
      return dispatch({ type: SAVE_CREDIT_NOTE, payload: { saved: false, loading: false } })
    })
  }
}