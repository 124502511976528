/*
    Configuración: routesConfig
    Descripción: Archivo de configuración de las rutas de la aplicación
*/

import React from "react"
import { Redirect } from "react-router-dom"
import AppUtils from "@app/AppUtils"

import { LoginConfig } from "components/pages/login/LoginConfig"
import { TrackingConfig } from "components/pages/tracking/TrackingConfig"
import { GridConfig } from "components/pages/grid/GridConfig"
import { ProductsConfig } from "components/pages/products/ProductsConfig"
import { DashboardConfig } from "components/pages/dashboard/DashboardConfig"
import { RoomConfig } from "components/pages/room/RoomConfig"
import { TouristPackagesConfig } from "components/pages/tourist_packages/TouristPackagesConfig"
import { ClientsProvidersConfig } from "components/pages/clients_providers/ClientsProvidersConfig"
import { VoucherConfig } from "components/pages/voucher/VoucherConfig"
import { EstablishmentsConfig } from "components/pages/establishments/EstablishmentsConfig"
import { SeriesConfig } from "components/pages/series/SeriesConfig"
import { UsersConfig } from "components/pages/users/UsersConfig"
import { SalesConfig } from "components/pages/sales/SalesConfig"
import { OrdersConfig } from "components/pages/orders/OrdersConfig"
import { ProformasConfig } from "components/pages/proformas/ProformasConfig"
import { ManageCashConfig } from "components/pages/manage_cash/ManageCashConfig"
import { AccessControlConfig } from "components/pages/access_control/AccessControlConfig"
import { ExpensesReceiptConfig } from "components/pages/expenses_receipt/ExpensesReceiptConfig"
import { IncomesReceiptConfig } from "components/pages/income_receipt/IncomesReceiptConfig"
import { ManageCpeConfig } from "components/pages/manage_cpe/ManageCpeConfig"
import { CreditNoteConfig } from "components/pages/credit_note/CreditNoteConfig"
import { ElectronicGuideConfig } from "components/pages/electronic_guide/ElectronicGuideConfig"
import { MenuControlConfig } from "components/pages/menu_control/MenuControlConfig"
import { ManagementDriverConfig } from "components/pages/management_driver/ManagementDriverConfig"
import { ExitVehiclesConfig } from "components/pages/exit_vehicles/ExitVehiclesConfig"
import { AdvanceShiftConfig } from "components/pages/advance_shift/AdvanceShiftConfig"
import { ParcelConfig } from "components/pages/parcel_register/ParcelConfig"
import { ReportExitVehiclesConfig } from "components/pages/report_exit_vehicles/ReportExitVehiclesConfig"
import { ReportAdvanceShiftConfig } from "components/pages/report_advance_shift/ReportAdvanceShiftConfig"
import { ReportParcelRegisterConfig } from "components/pages/report_parcel_register/ReportParcelRegisterConfig"
import { ReportSalesConfig } from "components/pages/report_sales/ReportSalesConfig"
import { BuysConfig } from "components/pages/buys/BuysConfig"
import { VehiclesConfig } from "../components/pages/vehicles/VehiclesConfig"
import { VehicleRequirementsConfig } from "../components/pages/vehicle_requirements/VehicleRequirementsConfig"
import { VehicleOrderServicesConfig } from "../components/pages/vehicle_service_orders/VehicleOrderServicesConfig"
import { EmployeesConfig } from "../components/pages/employees/EmployeesConfig"
import { AttentionServiceOrdersConfig } from "../components/pages/attention_service_orders/AttentionServiceOrdersConfig"
import { VehicleStationsConfig } from "../components/pages/vehicle_stations/VehicleStationsConfig"
import { ReportServiceOrdersConfig } from "../components/pages/report_service_orders/ReportServiceOrdersConfig"
import { AccountsPayableConfig } from "../components/pages/accounts_payable/AccountsPayableConfig"
import { AccountsReceivableConfig } from "../components/pages/accounts_receivable/AccountsReceivableConfig"
import { DispatchesConfig } from "../components/pages/dispatches/DispatchesConfig"
import { EntriesConfig } from "../components/pages/entries/EntriesConfig"
import { OutputsConfig } from "../components/pages/outputs/OutputsConfig"
import { PricesProductsConfig } from "../components/pages/prices_products/PricesProductsConfig"
import { OperatingExpensesConfig } from "../components/pages/operating_expenses/OperatingExpensesConfig"
import { LoansConfig } from "../components/pages/loans/LoansConfig"
import { StoresConfig } from "../components/pages/stores/StoresConfig"
import { ComplaintsBookConfig } from "../components/pages/complaints_book/ComplaintsBookConfig"
import { EstablishmentsComplaintsConfig } from "../components/pages/establishments_complaints/EstablishmentsComplaintsConfig"
import { TransfersConfig } from "../components/pages/transfers/TransfersConfig"

const routesConfig = [
  LoginConfig,
  TrackingConfig,
  GridConfig,
  ProductsConfig,
  DashboardConfig,
  RoomConfig,
  TouristPackagesConfig,
  ClientsProvidersConfig,
  VoucherConfig,
  EstablishmentsConfig,
  SeriesConfig,
  UsersConfig,
  SalesConfig,
  OrdersConfig,
  ProformasConfig,
  ManageCashConfig,
  AccessControlConfig,
  ExpensesReceiptConfig,
  IncomesReceiptConfig,
  ManageCpeConfig,
  CreditNoteConfig,
  ElectronicGuideConfig,
  MenuControlConfig,
  ManagementDriverConfig,
  ExitVehiclesConfig,
  AdvanceShiftConfig,
  ParcelConfig,
  ReportExitVehiclesConfig,
  ReportAdvanceShiftConfig,
  ReportParcelRegisterConfig,
  ReportSalesConfig,
  BuysConfig,
  VehiclesConfig,
  EmployeesConfig,
  VehicleRequirementsConfig,
  VehicleOrderServicesConfig,
  AttentionServiceOrdersConfig,
  VehicleStationsConfig,
  ReportServiceOrdersConfig,
  AccountsPayableConfig,
  AccountsReceivableConfig,
  DispatchesConfig,
  EntriesConfig,
  OutputsConfig,
  PricesProductsConfig,
  OperatingExpensesConfig,
  LoansConfig,
  StoresConfig,
  ComplaintsBookConfig,
  EstablishmentsComplaintsConfig,
  TransfersConfig,
]

const routes = [
  ...AppUtils.generateRoutesFromConfigs(routesConfig, null),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
  {
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
]

export default routes
