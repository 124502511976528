import * as Actions from "../../actions/app"

const initialState = {
  list_proformas: null,
  total_pages_proformas: 1,
  total_registers: 0,

  detail_proforma: null,
  set_crud_proformas: false,
}

const proformaReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LIST_PROFORMAS: {
      return {
        ...state,
        list_proformas: action.payload.detalles,
        total_pages_proformas: action.payload.totalPaginas,
        total_registers: action.payload.totalRegistros
      }
    }
    case Actions.CRUD_PROFORMAS: {
      return {
        ...state,
        set_crud_proformas: action.payload
      }
    }
    case Actions.GET_DETAIL_PROFORMAS: {
      return {
        ...state,
        detail_proforma: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default proformaReducer
