import axios from "axios"
import * as Actions from "components/auth/store/actions"
import { toast } from "react-toastify"

export const GET_ALL_OPENINGS = "[MANAGE CASH] GET ALL OPENINGS"
export const CRUD_OPENING = "[MANAGE CASH] CRUD OPENING"
export const GET_PRODUCTS_SOLD_OPENING = "[MANAGE CASH] GET PRODUCTS SOLD OPENING"
export const GET_TOTALS_FORMS_PAY_OPENING = "[MANAGE CASH] GET TOTALS FORMS PAY OPENING"
export const GET_INCOMES_AND_EXPENSES_BY_OPENING = "[MANAGE CASH] GET BOX MOVEMENTS"

export function getAllOpenings({ userId = 0, branchId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/apertura?idusuario=${userId}&idsucursal=${branchId}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ALL_OPENINGS, payload: response.data.detalles })
    })
}

export function saveOpening(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rapertura`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    toast.info('Registrando apertura...', { toastId: 'saveOpening', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
        toast.update('saveOpening', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('saveOpening', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('saveOpening', { render: "Error al registrar apertura", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function closeOpening(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/capertura`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    toast.info('Cerrando caja...', { toastId: 'closeOpening', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
        toast.update('closeOpening', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('closeOpening', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('closeOpening', { render: "Error al registrar el cierre de caja", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deleteOpenings(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eapertura`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    toast.info('Eliminando caja...', { toastId: 'deleteOpenings', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
        toast.update('deleteOpenings', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('deleteOpenings', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('deleteOpenings', { render: "Error al eliminar caja", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getProductsSoldByOpening(openingId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gproductosvendidoscaja/${openingId}`)
  return (dispatch) => {
    dispatch({ type: GET_PRODUCTS_SOLD_OPENING, payload: { loading: true, data: null } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PRODUCTS_SOLD_OPENING, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_PRODUCTS_SOLD_OPENING, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(GET_PRODUCTS_SOLD_OPENING, error)
      dispatch({ type: GET_PRODUCTS_SOLD_OPENING, payload: { loading: false, data: null } })
    })
  }
}

export function getTotalsFormsPayByOpening(openingId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gtotalesformaspagocaja/${openingId}`)
  return (dispatch) => {
    dispatch({ type: GET_TOTALS_FORMS_PAY_OPENING, payload: { loading: true, data: null } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_TOTALS_FORMS_PAY_OPENING, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_TOTALS_FORMS_PAY_OPENING, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(GET_TOTALS_FORMS_PAY_OPENING, error)
      dispatch({ type: GET_TOTALS_FORMS_PAY_OPENING, payload: { loading: false, data: null } })
    })
  }
}

export function getIncomesAndExpensesByOpening(openingId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gingresosyegresoscaja/${openingId}`)
  return (dispatch) => {
    dispatch({ type: GET_INCOMES_AND_EXPENSES_BY_OPENING, payload: { loading: true, data: null } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_INCOMES_AND_EXPENSES_BY_OPENING, payload: { loading: false, data: response.data.detalles } })
    }).catch((error) => {
      console.log(GET_INCOMES_AND_EXPENSES_BY_OPENING, error)
      dispatch({ type: GET_INCOMES_AND_EXPENSES_BY_OPENING, payload: { loading: false, data: null } })
    })
  }
}

export function revertClosing(closingId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/rcapertura/${closingId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    toast.info('Reviertiendo cierre de caja...', { toastId: 'revertClosing', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
        toast.update('revertClosing', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('revertClosing', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('revertClosing', { render: "Error al revertir cierre de caja", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}