import * as Actions from "../../actions/app"

const initialState = {

  openings: null,
  loading_crud_opening: false,
  crud_opening: false,

  loading_products_sold_opening: false,
  products_sold_opening: null,

  loading_totals_formspay_opening: false,
  totals_formspay_opening: null,

  loading_incomes_expenses_opening: false,
  incomes_expenses_opening: null,
}
const manageCashReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_OPENINGS: {
      return {
        ...state,
        openings: action.payload
      }
    }
    case Actions.CRUD_OPENING: {
      return {
        ...state,
        loading_crud_opening: action.payload.loading,
        crud_opening: action.payload.crud,
      }
    }
    case Actions.GET_PRODUCTS_SOLD_OPENING: {
      return {
        ...state,
        loading_products_sold_opening: action.payload.loading,
        products_sold_opening: action.payload.data
      }
    }
    case Actions.GET_TOTALS_FORMS_PAY_OPENING: {
      return {
        ...state,
        loading_totals_formspay_opening: action.payload.loading,
        totals_formspay_opening: action.payload.data
      }
    }
    case Actions.GET_INCOMES_AND_EXPENSES_BY_OPENING: {
      return {
        ...state,
        loading_incomes_expenses_opening: action.payload.loading,
        incomes_expenses_opening: action.payload.data
      }
    }
    default: {
      return state
    }
  }
}
export default manageCashReducer
