import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_ALL_BUYS = "[BUYS] GET ALL BUYS"
export const GET_BUY = "[BUYS] GET BUY"
export const CRUD_BUY = "[BUYS] CRUD BUY"

export const GET_PROVIDERS_CREDITS = "[CREDITS PROVIDERS] GET PROVIDERS CREDITS"
export const GET_CREDITS_BY_PROVIDER = "[CREDITS PROVIDERS] GET CREDITS BY PROVIDER"
export const CRUD_CREDIT_PROVIDER = "[CREDITS PROVIDERS] CRUD CREDIT PROVIDER"

export const GET_PAYMENTS_CREDIT_PROVIDER = "[PAYS CREDITS PROVIDERS] GET PAYMENTS CREDIT PROVIDER"
export const CRUD_PAY_CREDIT_PROVIDER = "[PAYS CREDITS PROVIDERS] CRUD PAY CREDIT PROVIDER"

export const GET_DUES_CREDIT_PROVIDER_BY_CREDIT = "[DUES CREDITS PROVIDERS] GET DUES CREDIT PROVIDER BY CREDIT"
export const CRUD_CREDIT_DUE_PROVIDER = "[DUES CREDITS PROVIDERS] CRUD CREDIT DUE PROVIDER"

export const GET_DUES_CREDIT_PROVIDER_BY_PROVIDER = "[DUES CREDITS PROVIDERS] GET DUES CREDIT PROVIDER BY PROVIDER"

export function getAllBuys() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/compra`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.cabecera)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_ALL_BUYS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getBuy(buyId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gcompra/${buyId}`)
  return (dispatch) => {
    dispatch({ type: GET_BUY, payload: { loading: true, data: null } })
    toast.info("Obteniendo compra...", { toastId: "getBuy", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_BUY, payload: { loading: false, data: response.data.detalles } })
      toast.dismiss("getBuy")
      return setTimeout(() => {
        dispatch({
          type: GET_BUY,
          payload: { loading: false, data: null }
        })
      }, 50)
    })
  }
}

export function saveBuy(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcompra`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_BUY, payload: { loading: true } })
    toast.info("Guardando compra...", { toastId: "saveBuy", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.update("saveBuy", { type: "success", render: response.data.mensaje, autoClose: 3000 })
        dispatch({
          type: CRUD_BUY, payload: {
            loading: false, crud: true, data: response.data.detalles.tipo_pago_id == 2 ? response.data.detalles : null
          }
        })
      } else {
        toast.update("saveBuy", { type: "error", render: response.data.mensaje, autoClose: 6000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_BUY, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_BUY, error)
      toast.error("Error al guardar la compra", { toastId: "saveBuy", autoClose: 6000 })
      dispatch({ type: CRUD_BUY, payload: { loading: false, crud: false, data: null } })
    })
  }
}

export function updateBuy(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/acompra`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_BUY, payload: { loading: true } })
    toast.info("Actualizando compra...", { toastId: "updateBuy", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_BUY, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: "updateBuy", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "updateBuy", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_BUY, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_BUY, error)
      toast.error("Error al actualizar la compra", { toastId: "updateBuy", autoClose: 5000 })
      dispatch({ type: CRUD_BUY, payload: { loading: false, crud: false } })
    })
  }
}

export function deleteBuy(buyId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ecompra/${buyId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_BUY, payload: { loading: true } })
    toast.info("Eliminando compra...", { toastId: "deleteBuy", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje, { toastId: "deleteBuy", autoClose: 5000 })
        dispatch({ type: CRUD_BUY, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje, { toastId: "deleteBuy", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_BUY, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_BUY, error)
      toast.error("Error al eliminar la compra", { toastId: "deleteBuy", autoClose: 5000 })
      dispatch({ type: CRUD_BUY, payload: { loading: false, crud: false } })
    })
  }
}

export function updateBuyFromOptions(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/acompradesdeopciones`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_BUY, payload: { loading: true } })
    toast.info("Actualizando compra...", { toastId: "updateBuyFromOptions", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({
          type: CRUD_BUY, payload: {
            loading: false, crud: true, data: response.data.detalles.tipo_pago_id == 2 ? response.data.detalles : null
          }
        })
        toast.update("updateBuyFromOptions", { type: "success", render: response.data.mensaje, autoClose: 3000 })
      } else {
        toast.update("updateBuyFromOptions", { type: "error", render: response.data.mensaje, autoClose: 6000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_BUY, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_BUY, error)
      toast.error("Error al guardar la compra", { toastId: "updateBuyFromOptions", autoClose: 6000 })
      dispatch({ type: CRUD_BUY, payload: { loading: false, crud: false, data: null } })
    })
  }
}

export function saveCreditProvider(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcreditoproveedor`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_CREDIT_PROVIDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: CRUD_CREDIT_PROVIDER, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje, { autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_CREDIT_PROVIDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_CREDIT_PROVIDER, error)
      return dispatch({ type: CRUD_CREDIT_PROVIDER, payload: { loading: false, crud: false } })
    })
  }
}

export function cancelCreditProvider(creditId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ccreditoproveedor/${creditId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_CREDIT_PROVIDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: CRUD_CREDIT_PROVIDER, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje, { autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_CREDIT_PROVIDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_CREDIT_PROVIDER, error)
      return dispatch({ type: CRUD_CREDIT_PROVIDER, payload: { loading: false, crud: false } })
    })
  }
}

export function getProvidersCredits() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gproveedorescreditos`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_PROVIDERS_CREDITS,
        payload: response.data.detalles,
      })
    })
}

export function getCreditsByProviderId(providerId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gcreditosproveedor/${providerId}`)
  return (dispatch) => {
    dispatch({ type: GET_CREDITS_BY_PROVIDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_CREDITS_BY_PROVIDER, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_CREDITS_BY_PROVIDER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(GET_CREDITS_BY_PROVIDER, error)
      dispatch({ type: GET_CREDITS_BY_PROVIDER, payload: { loading: false, data: null } })
    })
  }
}

export function getPaymentsCreditByProvider(providerId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gcpagosproveedor/${providerId}`)
  return (dispatch) => {
    dispatch({ type: GET_PAYMENTS_CREDIT_PROVIDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_PAYMENTS_CREDIT_PROVIDER, payload: { loading: false, data: response.data.detalles } })
      } else {
        dispatch({ type: GET_PAYMENTS_CREDIT_PROVIDER, payload: { loading: false, data: null } })
      }
      return setTimeout(() => {
        dispatch({ type: GET_PAYMENTS_CREDIT_PROVIDER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(GET_PAYMENTS_CREDIT_PROVIDER, error)
      dispatch({ type: GET_PAYMENTS_CREDIT_PROVIDER, payload: { loading: false, data: null } })
    })
  }
}

export function savePayCreditProvider(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcpagoproveedor`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PAY_CREDIT_PROVIDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: CRUD_PAY_CREDIT_PROVIDER, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje, { autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_PAY_CREDIT_PROVIDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_PAY_CREDIT_PROVIDER, error)
      return dispatch({ type: CRUD_PAY_CREDIT_PROVIDER, payload: { loading: false, crud: false } })
    })
  }
}

export function cancelPayCreditProvider(paydId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ccpagoproveedor/${paydId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_PAY_CREDIT_PROVIDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: CRUD_PAY_CREDIT_PROVIDER, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje, { autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_PAY_CREDIT_PROVIDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_PAY_CREDIT_PROVIDER, error)
      return dispatch({ type: CRUD_PAY_CREDIT_PROVIDER, payload: { loading: false, crud: false } })
    })
  }
}

export function getDuesCreditProviderByCredit(creditProviderId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gcreditoproveedorletras/${creditProviderId}`)
  return (dispatch) => {
    dispatch({ type: GET_DUES_CREDIT_PROVIDER_BY_CREDIT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_DUES_CREDIT_PROVIDER_BY_CREDIT, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_DUES_CREDIT_PROVIDER_BY_CREDIT, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(GET_DUES_CREDIT_PROVIDER_BY_CREDIT, error)
      dispatch({ type: GET_DUES_CREDIT_PROVIDER_BY_CREDIT, payload: { loading: false, data: null } })
    })
  }
}

export function saveDueCreditProvider(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcreditoproveedorletra`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_CREDIT_DUE_PROVIDER, payload: { loading: true, crud: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: CRUD_CREDIT_DUE_PROVIDER, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_CREDIT_DUE_PROVIDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_CREDIT_DUE_PROVIDER, error)
      return dispatch({ type: CRUD_CREDIT_DUE_PROVIDER, payload: { loading: false, crud: false } })
    })
  }
}

export function deleteDueCreditProvider(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/ecreditoproveedorletra`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_CREDIT_DUE_PROVIDER, payload: { loading: true, crud: false } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
        dispatch({ type: CRUD_CREDIT_DUE_PROVIDER, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_CREDIT_DUE_PROVIDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      console.log(CRUD_CREDIT_DUE_PROVIDER, error)
      return dispatch({ type: CRUD_CREDIT_DUE_PROVIDER, payload: { loading: false, crud: false } })
    })
  }
}

export function getDuesCreditProviderByProvider(providerId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gletrascreditoproveedor/${providerId}`)
  return (dispatch) => {
    dispatch({ type: GET_DUES_CREDIT_PROVIDER_BY_PROVIDER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_DUES_CREDIT_PROVIDER_BY_PROVIDER, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_DUES_CREDIT_PROVIDER_BY_PROVIDER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      console.log(GET_DUES_CREDIT_PROVIDER_BY_PROVIDER, error)
      dispatch({ type: GET_DUES_CREDIT_PROVIDER_BY_PROVIDER, payload: { loading: false, data: null } })
    })
  }
}