import * as Actions from "../../actions/app"

const initialState = {

  orders: null,
  total_pages_orders: 1,
  total_registers: 0,

  loading_order: false,
  order: null,

  loading_crud_order: false,
  crud_order: false,
  data_crud_order: null,

  loading_crud_product_order: false,
  crud_product_order: false,

  loading_products_order: false,
  products_order: null,


}

const orderReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ORDERS: {
      return {
        ...state,
        orders: action.payload.detalles,
        total_pages_orders: action.payload.totalPaginas,
        total_registers: action.payload.totalRegistros
      }
    }
    case Actions.GET_ORDER: {
      return {
        ...state,
        loading_order: action.payload.loading,
        order: action.payload.data,
      }
    }
    case Actions.CRUD_ORDER: {
      return {
        ...state,
        loading_crud_order: action.payload.loading,
        crud_order: action.payload.crud,
        data_crud_order: action.payload.data
      }
    }
    case Actions.CRUD_PRODUCT_ORDER: {
      return {
        ...state,
        loading_crud_product_order: action.payload.loading,
        crud_product_order: action.payload.crud
      }
    }
    case Actions.GET_PRODUCTS_ORDER: {
      return {
        ...state,
        loading_products_order: action.payload.loading,
        products_order: action.payload.data
      }
    }
    default: {
      return state
    }
  }
}

export default orderReducer
