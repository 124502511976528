import authService from "services/authService";
import { setUserData } from './user.actions';

export const LOGIN_ERROR = '[LOGIN] LOGIN_ERROR';
export const LOGIN_SUCCESS = '[LOGIN] LOGIN_SUCCESS';
export const LOGIN_LOADER = '[LOGIN] LOGIN_LOADER';
export function submitLogin(username, password) {
    return (dispatch) => {
        dispatch({
            type: LOGIN_LOADER
        });
        authService.signInWithUsernameAndPassword(username, password)
            .then((user) => {
                const tUser = [
                    user
                ]

                tUser.role = "admin";

                dispatch(setUserData(tUser));
                return dispatch({
                    type: LOGIN_SUCCESS
                });
            }
            )
            .catch(error => {
                return dispatch({
                    type: LOGIN_ERROR,
                    payload: error
                });
            })
    };
}