import { validateIsNumber } from "../validate-is-number"

export const getUnitMeasuresAndPricesProduct = (unitMeasuresProduct = []) => {

  let defaultReturn = {
    unit_measures: [],
    prices: [],
  }

  if (!unitMeasuresProduct) return defaultReturn

  if (!unitMeasuresProduct.length) return defaultReturn

  let unit_measures = unitMeasuresProduct.map((
    {
      unidad_medida_id, unidad_medida, abreviatura,
    }
  ) => (
    {
      unidad_medida_id, unidad_medida, abreviatura,
    }
  ))

  let prices = unitMeasuresProduct.map((
    {
      unidad_medida_id, precioventa, precio1, precio2, precio3, preciocredito
    }
  ) => {

    let data = {
      unidad_medida_id,
      precioventa,
    }

    if (validateIsNumber(precio1)) data = { ...data, precio1 }
    if (validateIsNumber(precio2)) data = { ...data, precio2 }
    if (validateIsNumber(precio3)) data = { ...data, precio3 }
    if (validateIsNumber(preciocredito)) data = { ...data, preciocredito }

    return data

  })

  return {
    unit_measures,
    prices
  }

}