import * as Actions from "../../actions/app"

const initialState = {
  loading_find: false,
  people_found: [],

  loading_crud: false,
  realized_crud: false,
  data_crud: null,
}

const peopleReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FIND_PEOPLE: {
      return {
        ...state,
        loading_find: action.payload.loading,
        people_found: [...action.payload.data],
      }
    }
    case Actions.CRUD_PEOPLE: {
      return {
        ...state,
        loading_crud: action.payload.loading,
        realized_crud: action.payload.crud,
        data_crud: action.payload.data,
      }
    }
    default: {
      return state
    }
  }
}
export default peopleReducer
