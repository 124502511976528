import * as Actions from "../../actions/app"
const initialState = {
  list_discrepancies: null,

  loading_list_credit_note: false,
  list_credit_note: null,
  total_pages_credit_note: 1,
  total_registers: 0,

  loading_save_credit_note: false,
  saved_credit_note: false,
}
const creditNote = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LIST_DISCREPANCIES: {
      return {
        ...state,
        list_discrepancies: action.payload
      }
    }
    case Actions.LOADING_LIST_CREDIT_NOTE: {
      return {
        ...state,
        loading_list_credit_note: action.payload,
      }
    }
    case Actions.GET_LIST_CREDIT_NOTE: {
      return {
        ...state,
        list_credit_note: action.payload.detalles,
        total_pages_credit_note: action.payload.totalPaginas,
        total_registers: action.payload.totalRegistros
      }
    }
    case Actions.SAVE_CREDIT_NOTE: {
      return {
        ...state,
        loading_save_credit_note: action.payload.loading,
        saved_credit_note: action.payload.saved
      }
    }
    default: {
      return state
    }
  }
}

export default creditNote
