import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const FIND_PEOPLE = "[PEOPLE] FIND PEOPLE"
export const CRUD_PEOPLE = "[PEOPLE] CRUD PEOPLE"

export function findPeople(field) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/persona/search?parametro=${field}`)
  return (dispatch) => {
    dispatch({ type: FIND_PEOPLE, payload: { loading: true, data: [] } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: FIND_PEOPLE, payload: { loading: false, data: response.data.detalles } })
    }).catch((err) => {
      dispatch({ type: FIND_PEOPLE, payload: { loading: false, data: [] } })
      toast.error(err, { toastId: "findPerson", autoClose: 5000 })
    })
  }
}

export function saveNewPerson(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rpersonaprovedor`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_PEOPLE, payload: { loading: true, crud: false, data: null } })
    toast.info("Guardando persona...", { toastId: "saveNewPerson", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_PEOPLE, payload: { loading: false, crud: true, data: response.data.detalles } })
        toast.update("saveNewPerson", { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_PEOPLE, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((err) => {
      console.log(err)
      dispatch({ type: CRUD_PEOPLE, payload: { loading: false, crud: false, data: null } })
      toast.update("saveNewPerson", { render: "Error al guardar persona", type: "error", autoClose: 5000 })
    })
  }
}