import axios from "axios"
import * as Actionss from "components/auth/store/actions"

import { toast } from "react-toastify"
export const GET_LIST_DRIVERS = "[MANAGEMENT DRIVER] GET LIST DRIVERS"
export const SAVE_DRIVER = "[MANAGEMENT DRIVER] SAVE DRIVER"
export const UPDATE_DRIVER = "[MANAGEMENT DRIVER] UPDATE DRIVER"
export const DELETE_DRIVER = "[MANAGEMENT DRIVER] DELETE DRIVER"

export function getManagementDriver() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/transportista`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_LIST_DRIVERS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function saveManagementDriver(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rtransportista`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_DRIVER, payload: { loading: true, saved: false, data: null } })
    toast.info("Guardando datos...", { toastId: "infoDriver", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.update("infoDriver", { render: response.data.mensaje, type: "success", autoClose: 3000 })
        dispatch({ type: SAVE_DRIVER, payload: { loading: false, saved: true, data: response.data.detalles } })
      } else {
        toast.update("infoDriver", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: SAVE_DRIVER, payload: { loading: false, saved: false, data: null } })
      }, 50)
    }).catch((err => {
      dispatch({ type: SAVE_DRIVER, payload: { loading: false, saved: false, data: null } })
      console.log(err)
      toast.update("infoDriver", { autoClose: 5000, type: "error" })
    }))
  }
}

export function updateManagementDriver(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/atransportista`, form)
  return (dispatch) => {
    dispatch({ type: UPDATE_DRIVER, payload: { loading: true, updated: false } })
    toast.info("Actualizando datos...", { toastId: "infoDriver", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.update("infoDriver", { render: response.data.mensaje, type: "success", autoClose: 3000 })
        dispatch({ type: UPDATE_DRIVER, payload: { loading: false, updated: true } })
      } else {
        toast.update("infoDriver", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: UPDATE_DRIVER, payload: { loading: false, updated: false } })
      }, 50)
    }).catch((err => {
      dispatch({ type: UPDATE_DRIVER, payload: { loading: false, updated: false } })
      console.log(err)
      toast.update("infoDriver", { autoClose: 5000, type: "error" })
    }))
  }
}

export function deleteManagementDriver(form) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/etransportista/${form.idtransportista}`, form)
  return (dispatch) => {
    request.then((response) => {
      toast.info("Eliminando datos...", { toastId: "infoDriver", autoClose: false })
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actionss.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.update("infoDriver", { render: response.data.mensaje, type: "success", autoClose: 3000 })
        dispatch({ type: DELETE_DRIVER, payload: true })
      } else {
        toast.update("infoDriver", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: DELETE_DRIVER, payload: false })
      }, 50)
    }).catch((err => {
      dispatch({ type: DELETE_DRIVER, payload: false })
      console.log(err)
      toast.update("infoDriver", { autoClose: 5000, type: "error" })
    }))
  }
}
