import * as Actions from "../../actions/app"

const initialState = {

  loading_summary_user: false,
  summary_user: null,

  loading_histogram_sales_user: false,
  histogram_sales_user: null,

  loading_summary_loans: false,
  summary_loans: null,

}

const dashboardReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_SUMMARY_USER_BY_DATE: {
      return {
        ...state,
        loading_summary_user: action.payload.loading,
        summary_user: action.payload.data
      }
    }
    case Actions.GET_HISTOGRAM_SALES_USER: {
      return {
        ...state,
        loading_histogram_sales_user: action.payload.loading,
        histogram_sales_user: action.payload.data
      }
    }
    case Actions.GET_SUMMARY_LOANS: {
      return {
        ...state,
        loading_summary_loans: action.payload.loading,
        summary_loans: action.payload.data
      }
    }
    default: {
      return state
    }
  }
}
export default dashboardReducer
