import { combineReducers } from "redux"

import message from "./message.reducer"
import grid from "./grid.reducer"
import chart from "./chart.reducer"
import products from "./products.reducer"
import room from "./room.reducer"
import touristPackages from "./touristPackages.reducer"
import clientsProviders from "./clients_providers.reducer"
import voucher from "./voucher.reducer"
import establishment from "./establishment.reducer"
import series from "./series.reducer"
import users from "./users.reducer"
import sales from "./sales.reducer"
import orders from "./order.reducer"
import proformas from "./proformas.reducer"
import manageCash from "./manage_cash.reducer"
import creditNote from "./credit_note.reducer"
import manageCpe from "./manage_cpe.reducer"
import electronicGuide from "./electronic_guide.reducer"
import dashboard from "./dashboard.reducer"
import accessControl from "./access_control.reducer"
import menuControl from "./menu_control.reducer"
import managementDriver from "./management_driver.reducer"
import exitVehicles from "./exit_vehicles.reducer"
import advanceShift from "./advance_shift.reducer"
import parcelRegister from "./parcel_register.reducer"
import buys from "./buys.reducer"
import vehicles from "./vehicles.reducer"
import vehicleRequirements from "./vehicle_requirements.reducer"
import employees from "./employees.reducer"
import vehicleServiceOrders from "./vehicle_service_orders.reducer"
import vehiclesInventories from "./vehicle_inventory.reducer"
import vehicleStations from "./vehicle_stations.reducer"
import seriesProduct from "./series_product.reducer"
import entriesAndOutputs from "./entries_and_outputs.reducer"
import people from "./people.reducer"
import incomesAndExpenses from "./incomes_and_expenses.reducer"
import operatingExpenses from "./operating_expenses.reducer"
import loansReducer from "./loans.reducer"
import stores from "./stores.reducer"
import complaintsBook from "./complaints_book.reducer"

const appReducers = combineReducers({
  accessControl,
  menuControl,
  message,
  grid,
  chart,
  products,
  room,
  touristPackages,
  clientsProviders,
  voucher,
  establishment,
  series,
  users,
  sales,
  orders,
  proformas,
  manageCash,
  creditNote,
  manageCpe,
  electronicGuide,
  dashboard,
  managementDriver,
  exitVehicles,
  advanceShift,
  parcelRegister,
  buys,
  employees,
  vehicles,
  vehicleRequirements,
  vehicleServiceOrders,
  vehiclesInventories,
  vehicleStations,
  seriesProduct,
  entriesAndOutputs,
  people,
  incomesAndExpenses,
  operatingExpenses,
  loansReducer,
  stores,
  complaintsBook,
})

export default appReducers
