import React from "react"
import { AuthRoles } from "components/auth"

export const ReportAdvanceShiftConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/report_advance_shift`,
      component: React.lazy(() => import("./ReportAdvanceShift")),
    },
  ],
}
