import React from "react"
import { AuthRoles } from "components/auth"

export const ReportParcelRegisterConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/report_parcel_register`,
      component: React.lazy(() => import("./ReportParcelRegister")),
    },
  ],
}
